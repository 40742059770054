import { declareCallable } from '../base/callable.js';
import '@angular/fire/firestore';
import 'rxjs';
import 'rxjs/operators';

let getSubscriptionPortalSessionCallable;
function getSubscriptionPortalSession(ff, channelId, returnUrl) {
  if (!getSubscriptionPortalSessionCallable) {
    getSubscriptionPortalSessionCallable = declareCallable("stripe-openCustomerPortal", ff);
  }
  return getSubscriptionPortalSessionCallable({ channelId, returnUrl });
}

export { getSubscriptionPortalSession };

