/* istanbul ignore file */

import { AbstractControl, FormControl } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Utilitary pipe to typecast an AbstractControl as FormControl.
 * This allows binding a form group control to a FormControl input.
 */
@Pipe({ name: 'asFormControl', standalone: true })
export class AsFormControlPipe implements PipeTransform {
  transform(value: AbstractControl | null): FormControl {
    if (!value) {
      throw new Error('Bad AbstractControl');
    }
    return value as FormControl;
  }
}
