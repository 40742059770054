import { httpsCallable } from '@angular/fire/functions';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

function declareCallable(functionName, f) {
  return (params) => {
    return from(
      httpsCallable(f, functionName)(params)
    ).pipe(
      map((r) => convertBackDate(r.data))
    );
  };
}
function convertBackDate(data) {
  if (Array.isArray(data)) {
    return data.map((d) => convertBackDate(d));
  } else if (typeof data === "object" && data !== null) {
    if ("__date" in data) {
      return new Date(data.__date);
    }
    const newData = {};
    for (const [k, value] of Object.entries(data)) {
      newData[k] = convertBackDate(value);
    }
    return newData;
  } else {
    return data;
  }
}

export { convertBackDate, declareCallable };

