import { debounceTime, map, switchMap, from } from 'rxjs';
import { getAuthenticatedUserInfo$, updateUserDoc } from './crud.js';
import { hashString } from '../utils.js';

async function computeUserFingerprint(user) {
  const hash = await hashString(user.refreshToken);
  const fingerprint = Array.from(hash).map((d) => `${d < 16 ? "0" : ""}${d.toString(16)}`).join("");
  return fingerprint;
}
function monitorAuthenticatedUserFingerprint(auth, fs) {
  if (!auth.currentUser) {
    throw new TypeError(
      "This function should only be called when the user is authenticated"
    );
  }
  return getAuthenticatedUserInfo$(auth, fs).pipe(
    debounceTime(5e3),
    // Make sure that the user is logged in and what we expect
    map((userInfo) => {
      const currentUser = auth.currentUser;
      if (!currentUser || currentUser.uid !== userInfo.docId) {
        throw new Error("Current user is not the same as the monitored user");
      }
      return [userInfo, currentUser];
    }),
    // Switch map to the fingerprint calculation (which is a promise)
    switchMap(([userInfo, currentUser]) => from(computeUserFingerprint(currentUser).then((fingerprint) => [userInfo, fingerprint]))),
    // Return the result of the comparison between the current user fingerprint(from the session) and the global one (from the latest login session)
    map(
      ([userInfo, fingerprint]) => typeof userInfo?.fingerprint === "string" && userInfo.fingerprint.split(":")[0] === fingerprint
    )
  );
}
function updateUserFingerprint(fs, user) {
  return from(
    computeUserFingerprint(user)
  ).pipe(
    switchMap((fingerprint) => updateUserDoc(fs, user.uid, { fingerprint: `${fingerprint}:${Date.now()}` }))
  );
}

export { computeUserFingerprint, monitorAuthenticatedUserFingerprint, updateUserFingerprint };

