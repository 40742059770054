import { declareCallable } from '../base/callable.js';
import '@angular/fire/firestore';
import 'rxjs';
import 'rxjs/operators';

let cachedGetAccessCallable;
function getAccess(ff, channelId, videoId) {
  if (!cachedGetAccessCallable) {
    cachedGetAccessCallable = declareCallable("accesscontrol-check", ff);
  }
  return cachedGetAccessCallable({ channelId, videoId });
}

export { getAccess };

