/**
 * Given an input text, parses it and return a list of document nodes that can be appended.
 * This is primarily used for converting text formatted in a specific way into styled HTML markup.
 *
 * @remarks Client musts use the "yoContentParser" directive
 */

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { PARSER_IMPLEMENTATION_TOKEN } from '../interfaces';

export interface ParserImplementation {
  parse(input: string, document: Document): (HTMLElement | Text)[];
}

const WHITE_LISTED_ELEMENTS = [
  // Block elements
  'blockquote',
  'dd',
  'dl',
  'dt',
  'hr',
  'li',
  'ol',
  'p',
  'ul',
  // Inline elements,
  'a',
  'b',
  'br',
  'cite',
  'em',
  'i',
  'q',
  's',
  'small',
  'span',
  'strong',
  'sub',
  'sup',
].map((e) => e.toUpperCase());

@Injectable({ providedIn: 'root' })
export class TextParserService {
  constructor(
    @Inject(PARSER_IMPLEMENTATION_TOKEN) private impl: ParserImplementation,
    @Inject(DOCUMENT) readonly document: Document
  ) {}

  parse(input: string): Node[] {
    return this.sanitizeOutput(this.impl.parse(input, this.document));
  }

  replaceContent<T extends Element>(targetNode: T, content: Node[]): T {
    targetNode.innerHTML = '';
    for (const node of content) {
      targetNode.appendChild(node);
    }
    return targetNode;
  }

  private sanitizeAttributes(element: Element) {
    Array.from(element.attributes).forEach((attr) => {
      if (
        ['src', 'href', 'xlink:href'].includes(attr.name) &&
        (attr.value.includes('javascript:') ||
          attr.value.includes('data:text/html'))
      ) {
        element.removeAttribute(attr.name);
      }

      if (attr.name.startsWith('on')) {
        element.removeAttribute(attr.name);
      }
    });
  }
  private sanitizeOutput(elements: Node[]): Node[] {
    const filtered = elements.filter((e) => {
      if (
        e.nodeType === e.TEXT_NODE ||
        (e.nodeType === e.ELEMENT_NODE &&
          WHITE_LISTED_ELEMENTS.includes(e.nodeName))
      ) {
        // Sanitize child nodes
        if (e.hasChildNodes()) {
          this.sanitizeOutput(Array.from(e.childNodes));
        }
        // Make sure we remove dangerous attributes
        if ('attributes' in e) {
          this.sanitizeAttributes(e as Element);
        }
        return true;
      } else {
        // Remove from parent if parent is Defined
        if (e.parentNode) {
          e.parentNode.removeChild(e);
        }
        return false;
      }
    });
    return filtered;
  }
}
