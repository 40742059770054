import { isPlatformServer } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';

import { Scope } from '@yoimo/client-sdk/channels';
import { WindowService } from '@yoimo/joymo-ui';

import { Request, Response } from 'express';
import { REQUEST, RESPONSE } from '../../../express.tokens';
import { ScopeService } from './scope.service';

@Injectable({ providedIn: 'root' })
export class PlatformService {
  constructor(
    private windowService: WindowService,
    private scopeService: ScopeService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject('CUSTOM_DOMAIN') private domain?: string,
    @Optional() @Inject(RESPONSE) private res?: Response,
    @Optional() @Inject(REQUEST) private req?: Request
  ) {}

  isServer() {
    return isPlatformServer(this.platformId);
  }

  /**
   * on the server return the custom domain set in the `server.ts` file.
   * on the browser the domain can be fetched from location.hostname.
   * @returns the appropriate domain that should be used to fetch scope.
   */
  getDomain(): string {
    return this.domain || this.windowService.document.location.hostname;
  }

  /** Redirect to domain attached to scope's entity if not matching the current hostname */
  ensureValidDomainForScope(scope: Scope): void {
    // Check whether user is on correct domain, if not redirect
    const scopeDomain = this.scopeService.getDomainFromScope(scope);
    const currentDomain = this.getDomain();

    if (!scopeDomain) return;
    if (scopeDomain === currentDomain) return;

    const path = this.windowService.document.location.pathname;
    this.changeDomain(scopeDomain, path || '');
  }

  /**
   * Used to redirect to a specific domain & path pair.
   * Scenarios:
   *  - SSR: redirect in the server
   *  - CSR: redirect in the browser
   *
   * @param domain Domain target to redirect to
   * @param path URL Path to use.
   */
  private changeDomain(domain: string, path: string): void {
    let url = new URL(path, this.getFormattedBase(domain));

    if (this.isServer() && this.res && this.req) {
      this.res.redirect(HttpStatusCode.Found, url.toString());
      return;
    }
    if (!this.windowService.window) return;

    url = new URL(path, this.getFormattedBase(domain));
    // Use replace to prevent the user from getting stuck in a redirection loop if they try to go back
    this.windowService.window.location.replace(url);
  }

  /**
   * @param domain Target domain
   * @returns domain if it has a protocol part or a platform dependant formatted origin.
   * @examples https://domain.com
   */
  private getFormattedBase(domain: string): string {
    return domain.startsWith('http') ? domain : this.getFormattedOrigin(domain);
  }

  /**
   * @param domain Target domain
   * @returns a platform specific formatted origin
   * @examples https://domain.com
   */
  private getFormattedOrigin(domain: string): string {
    if (this.isServer() && this.req) {
      return `${this.req.protocol}://${domain}`;
    } else if (this.windowService.window) {
      return `${this.windowService.window.location.protocol}//${domain}`;
    }

    return `https://${domain}`;
  }
}
