import { getDocData, collectionRef } from '../base/collection.js';
import { map } from 'rxjs';
import '@angular/fire/functions';
import 'rxjs/operators';
import { pagedQuery, batchedInQuery, buildQuery, execQuery } from '../base/query.js';
import { converter } from './EventDataConverter.js';

function EVENTS_COLLECTION(channelId) {
  return `channels/${channelId}/events`;
}
function colRef(fs, channelId) {
  return collectionRef(EVENTS_COLLECTION(channelId), fs, converter());
}
function getEvent(fs, channelId, id) {
  return getDocData(id, colRef(fs, channelId));
}
function getEventsByIds(col, eventIds) {
  return batchedInQuery(col, eventIds).pipe(
    map((events) => events.filter((v) => v !== void 0))
  );
}
function getEventsByQuery(col, source, options) {
  const [qWhere, qOptions, now] = getQueryParamsFromSourceList(source, options);
  const query = buildQuery(col, qWhere, qOptions);
  return execQuery(query).pipe(
    map((results) => results.filter(({ archived, published }) => !archived && published)),
    map((results) => results.filter((s) => source.onlyState !== "ACTIVE" ? true : s.startTime <= now))
  );
}
function getQueryParamsFromSourceList(source, options) {
  const now = options.nowRef ? options.nowRef : /* @__PURE__ */ new Date();
  const sort = (field) => {
    if (field && options.sort && options.sort === "DATE") {
      return [{
        field,
        sort: options.order === "ASC" ? "asc" : "desc"
      }];
    } else {
      return void 0;
    }
  };
  switch (source.onlyState) {
    case "PASSED":
      return [
        [["endTime", "<", now]],
        {
          qLimit: options.limit,
          qOrderBy: sort("endTime")
        },
        now
      ];
    case "ACTIVE":
      return [
        [["endTime", ">", now]],
        {
          qLimit: options.limit,
          qOrderBy: sort("endTime")
        },
        now
      ];
    case "ACTIVE_AND_UPCOMING":
      return [
        [["endTime", ">", now]],
        {
          qLimit: options.limit,
          qOrderBy: sort("endTime")
        },
        now
      ];
    case "UPCOMING":
      return [
        [["startTime", ">", now]],
        {
          qLimit: options.limit,
          qOrderBy: sort("startTime")
        },
        now
      ];
    case "PASSED_AND_ACTIVE":
      return [
        [["startTime", "<", now]],
        {
          qLimit: options.limit,
          qOrderBy: sort("startTime")
        },
        now
      ];
    default:
      return [
        [],
        {
          qLimit: options.limit,
          qOrderBy: sort("startTime")
        },
        now
      ];
  }
}
function getEventsFromSource(fs, source, options) {
  if (options.scope === "ORGANIZATION") {
    throw new Error("Organization scopes are not implemented");
  }
  const col = colRef(fs, options.scopeId);
  if (source.eventIds) {
    return getEventsByIds(col, source.eventIds);
  } else {
    return getEventsByQuery(col, source, options);
  }
}
function getEventsFromSourcePaged(fs, source, options) {
  if (options.scope === "ORGANIZATION") {
    throw new Error("Scope other than CHANNEL is not supported");
  }
  if (source.eventIds) {
    throw new Error("Only dynamic sources are supported when using paging.");
  }
  const col = colRef(fs, options.scopeId);
  const [wheres, qOptions] = getQueryParamsFromSourceList(source, options);
  return pagedQuery(col, wheres, options.pager, qOptions);
}

export { getEvent, getEventsFromSource, getEventsFromSourcePaged };

