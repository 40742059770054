/* istanbul ignore file */

import { Pipe, PipeTransform } from '@angular/core';

/**
 * Utilitary pipe to translform a list into a grouped Map given an grouping property.
 */
@Pipe({ name: 'listAsMap', standalone: true })
export class ListAsMapPipe implements PipeTransform {
  transform(
    list: any,
    groupByProperty: string | number
  ): Map<string | number, any[]> {
    const itemsMap = list.reduce(
      (groupedMap: Map<string, any[]>, item: any) => {
        const group = groupedMap.get(item[groupByProperty]);
        groupedMap.set(
          item[groupByProperty],
          !group ? new Array(item) : [...group, item]
        );
        return groupedMap;
      },
      new Map<string, any[]>()
    );
    return itemsMap;
  }
}
