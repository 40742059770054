import '@angular/fire/functions';
import 'rxjs';
import 'rxjs/operators';
import '@angular/fire/firestore';
import { cacheConverter, DefaultDataConverter } from '../base/DefaultDataConverter.js';
import { SoldTicket } from '@yoimo/interfaces/validators';

class SoldTicketConverter extends DefaultDataConverter {
  constructor() {
    super(SoldTicket);
  }
}
const converter = cacheConverter(SoldTicketConverter);

export { SoldTicketConverter, converter };

