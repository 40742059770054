import { ActivatedRouteSnapshot } from '@angular/router';
import { LocaleMapping } from 'shared-lib/core';

export const MAX_PER_UNIT = 60;
export const MILLISECOND = 1000;
export const SECOND = 1;
export const MINUTE = MAX_PER_UNIT * MILLISECOND;
export const HOUR = MAX_PER_UNIT * MINUTE;

export const findDataFromRouteTree = (
  key: string,
  route?: ActivatedRouteSnapshot | null
): any | undefined => {
  if (!route) {
    // console.error(`Could not find ${key}, returning undefined`);
    return undefined;
  }
  return route.data[key] || findDataFromRouteTree(key, route.parent);
};

export const findParamFromRouteTree = (
  key: string,
  route?: ActivatedRouteSnapshot | null
): string | undefined => {
  if (!route) {
    return undefined;
  }
  return route.paramMap.get(key) || findParamFromRouteTree(key, route.parent);
};

export const isDefined = <T>(element: T | undefined): element is T => {
  return element !== undefined;
};

export function sortArrayOfObjects<T>(
  arr: T[],
  key: keyof T,
  order: 'asc' | 'desc' = 'asc'
): T[] {
  const multiple = order === 'asc' ? 1 : -1;
  arr.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1 * multiple;
    }
    if (a[key] > b[key]) {
      return 1 * multiple;
    }
    return 0;
  });
  return arr;
}

export function emptyStringIfNull(s: string | undefined | null): string {
  return s ? s : '';
}

export function boolSwitch<T>(
  predicate: boolean,
  trueValue: T,
  falseValue: T
): T {
  return predicate ? trueValue : falseValue;
}

export function formatPrice(
  locale: string,
  price: number,
  currency: string
): string {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(price);
}

export const localeMapping: LocaleMapping = {
  filtersFloatingButtonLabel: $localize`:@@videoFiltersFloatingButton:Filters`,
  dateRangePlaceholder: $localize`:@@dateRangePlaceholder:Filter by date`,
  dateRangeSubmitLabel: $localize`:@@dateRangeSubmitLabel:Apply`,
  dateRangeClearLabel: $localize`:@@dateRangeClearLabel:Clear range`,
  attributeApplyLabel: $localize`:@@attributeApplyLabel:Apply`,
  attributeResetFilterLabel: $localize`:@@attributeResetFilterLabel:Reset filter`,
  attributesLiveAndUpcomingLabel: $localize`:@@attributesLiveAndUpcomingLabel:Live and upcoming`,
  attributesShowAllStreamsLabel: $localize`:@@attributesShowAllStreamsLabel:Show all streams`,
  attributesFilterByDateLabel: $localize`:@@attributesFilterByDateLabel:Filter by date`,
  calendarPageNoStreamsFoundHeading: $localize`:@@CalendarPageNoStreamsFoundHeading:No streams found`,
  calendarPageNoStreamsFoundDescription: $localize`:@@CalendarPageNoStreamsFoundDescription:No streams matching your criteria, or they haven't been scheduled yet.`,
  videoFiltersPastAndOngoingEventLabel: $localize`:@@videoFiltersPastAndOngoingEventLabel:Past and Ongoing`,
  videoFiltersUpcomingEventLabel: $localize`:@@videoFiltersUpcomingEventLabel:Upcoming`,
  videoFiltersSelectedLabel: $localize`:@@videoFiltersSelectedLabel:selected`,
  liveLabel: $localize`:@@videoLiveLabel:Live`,
  videoLockText: $localize`:@@videoLockTooltipText:Access the stream with a ticket or subscription`,
  noActivePageMessage: $localize`:@@noActivePageMessage:No active page`,
  loadMoreButtonLabel: $localize`:@@loadMoreButtonLabel:Load more`,
  socialsShareMessage: $localize`:@@socialsShareMessage:Support the channel, share the video`,
};
