import { collection, doc, getDoc, docData, updateDoc } from '@angular/fire/firestore';
import { from } from 'rxjs';

function isCollectionReference(col) {
  return col !== null && typeof col === "object" && "type" in col && col.type === "collection";
}
function collectionRef(path, fs, converter) {
  return collection(fs, path).withConverter(converter);
}
function isRef(idOrRef) {
  return !!idOrRef && typeof idOrRef !== "string";
}
function docRef(collection2, idOrRef) {
  return isRef(idOrRef) ? idOrRef : doc(collection2, idOrRef);
}
function getDocData(idOrRef, collection2) {
  let promise;
  if (isRef(idOrRef)) {
    promise = getDoc(idOrRef);
  } else {
    if (!isCollectionReference(collection2)) {
      throw new TypeError("Can't resolve to a document reference since no collection was provided");
    }
    promise = getDoc(docRef(collection2, idOrRef));
  }
  return from(promise.then((snapshot) => snapshot.data()));
}
function getDocData$(idOrRef, collection2) {
  if (isRef(idOrRef)) {
    return docData(idOrRef);
  }
  if (!isCollectionReference(collection2)) {
    throw new TypeError("Can't resolve to a document reference since no collection was provided");
  }
  return docData(docRef(collection2, idOrRef), { idField: "docId" });
}
function updateDocData(update, idOrRef, collection2) {
  let ref;
  if (typeof update !== "object" || update === null) {
    throw new TypeError("Invalid update object provided");
  }
  if (!isRef(idOrRef)) {
    if (!isCollectionReference(collection2)) {
      throw new TypeError("Can't resolve to a document reference since no collection was provided");
    }
    ref = docRef(collection2, idOrRef);
  } else {
    ref = idOrRef;
  }
  return from(updateDoc(ref, update));
}

export { collectionRef, docRef, getDocData, getDocData$, isCollectionReference, updateDocData };

