import { getChannelsByIds } from '../channels/crud.js';
import '@angular/fire/functions';
import { takeWhile, filter, of, map } from 'rxjs';
import 'rxjs/operators';
import { getDocData, getDocData$, updateDocData, collectionRef } from '../base/collection.js';
import '@angular/fire/firestore';
import '@yoimo/interfaces/validators';
import { converter } from './UserInfoDataConverter.js';

const USERS_COLLECTION = "users";
function colRef(fs) {
  return collectionRef(USERS_COLLECTION, fs, converter());
}
function getAuthenticatedUserInfo(auth, fs) {
  return auth.currentUser ? getDocData(auth.currentUser.uid, colRef(fs)) : void 0;
}
function getAuthenticatedUserInfo$(auth, fs) {
  if (!auth.currentUser) {
    throw new Error("User not authenticated, this function should only be called if the user is registered");
  }
  return getUserInfo$(auth.currentUser.uid, fs).pipe(
    takeWhile(() => !!auth.currentUser),
    filter((userInfo) => !!userInfo)
  );
}
function getUserInfo$(userId, fs) {
  return getDocData$(userId, colRef(fs));
}
function getChannelsSubscriptions(userInfo, fs) {
  if (!userInfo.subscriptions) {
    return of([]);
  }
  return getChannelsByIds(fs, Object.keys(userInfo.subscriptions)).pipe(
    map((channels) => channels.filter((c) => c !== void 0))
  );
}
function updateUserDoc(fs, userId, update) {
  return updateDocData(update, userId, colRef(fs));
}

export { getAuthenticatedUserInfo, getAuthenticatedUserInfo$, getChannelsSubscriptions, getUserInfo$, updateUserDoc };

