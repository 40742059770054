import '@angular/fire/functions';
import 'rxjs';
import 'rxjs/operators';
import '@angular/fire/firestore';
import { cacheConverter, DefaultDataConverter } from '../base/DefaultDataConverter.js';
import { NamedTag } from '@yoimo/interfaces/validators';

class NamedTagDataConverter extends DefaultDataConverter {
  constructor() {
    super(NamedTag);
  }
}
const converter = cacheConverter(NamedTagDataConverter);

export { NamedTagDataConverter, converter };

