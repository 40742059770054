import {
  EffectivePlanPrice,
  PlanWithDocId,
  getPlanAlternatives,
} from '@yoimo/client-sdk/subscriptions';
import { EventWithDocId, getEffectivePrice } from '@yoimo/client-sdk/events';
import {
  PlanOption,
  TicketOption,
  TicketOptionState,
} from './ticket-options-types';
import { emptyStringIfNull, formatPrice } from '@core/utilities/utils';

import { ChannelWithDocId } from '@yoimo/client-sdk/channels';
import { Ticket, TicketPrice } from '@yoimo/interfaces';
import { VideoWithDocId } from '@yoimo/client-sdk/videos';
import {
  getTicketExpiry,
  isTicketRestricted,
  isTicketAvailableForPurchase,
  willTicketExpireBy,
  InvalidTicketPrice,
} from '@yoimo/client-sdk/business-logic';
import { ParsePeriodicityPipe } from '@core/pipes';

// #TODO: this should probably be transformed to a Pipe directive to be used in the views.
function formatDate(locale: string, date: Date): string {
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  }).format(date);
}

const periodicityPipe = new ParsePeriodicityPipe();

/**
 * Parses a ticket and returns a TicketOption object with the required
 * details populated.
 * @param ticket Ticket to parse
 * @param event event containing the ticket
 * @param video Optional video
 * @returns Returns a TicketOption object with all the required details
 */
export function ticketToTicketOption(
  channel: ChannelWithDocId,
  ticket: Ticket,
  event: EventWithDocId,
  video: VideoWithDocId | undefined,
  locale: string,
  homepageUrl: string,
  validSoldTicket?: boolean
): TicketOption {
  const effectivePrice = getEffectivePrice(ticket, true, event);
  const ticketExpires = ticket.scope?.validityAfterRedemptionHours;
  const isRedemptionLimited = !!(ticket.scope && ticket.scope.redemptionLimit);
  const state = getTicketOptionState(ticket, event, effectivePrice);
  const optionPrice = !('reason' in effectivePrice)
    ? effectivePrice
    : effectivePrice.reason === 'FUTURE_TICKET'
    ? effectivePrice.price
    : undefined;
  return {
    type: 'TICKET',
    state,
    optionPrice,
    ticketData: ticket,
    eventId: event.docId,
    videoId: video?.docId,
    cardDetails: {
      ...getTicketTitles(locale, ticket, event, video, ticketExpires),
      description: ticket.description,
      ...getCoverImageForTicket(channel, ticket, event, video),
      ...getTicketCardStateAndActionLabel(
        optionPrice,
        locale,
        state,
        validSoldTicket
      ),
      price: optionPrice
        ? {
            amount: optionPrice.price,
            currency: optionPrice.currency,
          }
        : undefined,
      toProductListLink: isRedemptionLimited
        ? ['']
        : [homepageUrl, 'events', event.docId, 'tickets', ticket.id],
      toProductListLinkLabel: isRedemptionLimited
        ? ''
        : $localize`:@@TicketCardShowStreamsLink:Show streams`,
    },
    isBoughtByUser: !!validSoldTicket,
  };
}

export function getTicketOptionState(
  ticket: Ticket,
  event: EventWithDocId,
  effectivePrice: TicketPrice | InvalidTicketPrice
): TicketOptionState {
  if (!ticket.available) {
    return 'UNAVAILABLE';
  }
  if (
    !isTicketAvailableForPurchase(ticket, event) &&
    willTicketExpireBy(event, ticket.scope)
  ) {
    return 'EXPIRED';
  }
  if ('reason' in effectivePrice) {
    return effectivePrice.reason;
  }

  return 'AVAILABLE';
}

export function getTicketCardStateAndActionLabel(
  ticketPrice: TicketOption['optionPrice'],
  locale: string,
  optionState: TicketOptionState,
  validSoldTicket?: boolean
): Pick<TicketOption['cardDetails'], 'state' | 'actionButtonLabel'> {
  const ticketActionLabelMap: Record<
    TicketOptionState | TicketOption['cardDetails']['state'],
    string
  > = {
    EXPIRED: $localize`:@@TicketCardExpiredTicket:Ticket is expired`,
    FUTURE_TICKET: $localize`:@@TicketCardFutureTicket:Coming soon`,
    NO_PRICE: $localize`:@@TicketCardNoPriceLabel:Price not set`,
    UNAVAILABLE: $localize`:@@TicketCardUnavailable:Ticket is unavailable`,
    PURCHASED: $localize`:@@TicketCardInYourTicketsLabel:In your tickets`,
    PURCHASING: ticketPrice
      ? formatPrice(locale, ticketPrice.price, ticketPrice.currency)
      : '',
    // Not used, broken down into PURCHASED | PURCHASING
    AVAILABLE: '',
  };

  if (optionState !== 'AVAILABLE') {
    return {
      state: 'UNAVAILABLE',
      actionButtonLabel: ticketActionLabelMap[optionState],
    };
  }

  const cardState = validSoldTicket ? 'PURCHASED' : 'PURCHASING';
  return {
    state: cardState,
    actionButtonLabel: ticketActionLabelMap[cardState],
  };
}

export function getTicketTitles(
  locale: string,
  ticket: Ticket,
  event: EventWithDocId,
  video?: VideoWithDocId,
  validity?: number | null
): Pick<
  TicketOption['cardDetails'],
  'title' | 'description' | 'validityOrExpiryInfo'
> {
  const ticketExpiry = getTicketExpiry(ticket.scope, event);
  const isRedemptionLimited = !!(ticket.scope && ticket.scope.redemptionLimit);
  let lines = ['', '', '', '', '', ''];

  if (isTicketRestricted(ticket) || (isRedemptionLimited && video)) {
    lines[0] = ticket.name;
    lines[1] = isRedemptionLimited && video ? video.name : event.title;
  } else {
    lines[0] = event.title;
    lines[1] = $localize`:@@TicketCardTitleFullAccess:Full access to the event`;
  }

  lines[2] =
    ticketExpiry.type === 'ABSOLUTE'
      ? $localize`:@@TicketCardTicketExpiresOn:Expires: ${formatDate(
          locale,
          ticketExpiry.date
        )}:date:`
      : validity
      ? getExpiryTimeLabel(validity)
      : '';
  lines[3] = ticket.description;

  return {
    title: lines[0],
    validityOrExpiryInfo: lines[2],
    description: lines[3],
  };
}

export function getExpiryTimeLabel(hours: number): string {
  const days = Math.floor(hours / 24);
  const hrs = Math.round(hours % 24);

  if (days && hrs) {
    return $localize`:@@ticketValidForDaysAndHours:Ticket is valid for ${days}:days: days and ${hrs}:hours: h after first use`;
  }
  if (days) {
    return $localize`:@@ticketValidForDays:Ticket is valid for ${days}:days: days after first use`;
  }
  if (hrs) {
    return $localize`:@@ticketValidForHours:Ticket is valid for ${hrs}:hours: h after first use`;
  }
  throw new Error('Invalid hour');
}

export function getCoverImageForTicket(
  channel: ChannelWithDocId,
  ticket: Ticket,
  event: EventWithDocId,
  video?: VideoWithDocId
): Pick<
  TicketOption['cardDetails'],
  'coverImg' | 'coverImgIsPlaceholder' | 'foregroundImg'
> {
  const isRestricted = isTicketRestricted(ticket);
  const isRedemptionLimited = !!(
    ticket.scope &&
    ticket.scope.redemptionLimit &&
    video
  );
  if (isRedemptionLimited) {
    // Single ticket event place the video poster and the logo of the club
    return {
      coverImg: video.posterImage,
      foregroundImg: channel.branding.logoImage || '',
      coverImgIsPlaceholder: false,
    };
  } else if (isRestricted && ticket.ticketImage) {
    return {
      coverImg: ticket.ticketImage,
      foregroundImg: '',
      coverImgIsPlaceholder: false,
    };
  } else if (isRestricted && !ticket.ticketImage) {
    return {
      coverImg: channel?.branding.logoImage || '',
      foregroundImg: '',
      coverImgIsPlaceholder: true,
    };
  } else {
    return {
      coverImg: event.posterImage,
      foregroundImg: '',
      coverImgIsPlaceholder: false,
    };
  }
}

/**
 * Take a Plan and transform it into one or many PlanOptions
 *
 * @param channel Channel that provides the plan
 * @param plan Plan to parse
 * @param uniquePlan If set to true it will output multiple options, each with only one alternative
 * @param locale Client locale
 */
export function planToPlanOption(
  channel: ChannelWithDocId,
  plan: PlanWithDocId,
  locale: string,
  allowTrial: boolean
): PlanOption {
  const alternatives = getPlanAlternatives(plan);
  const highlighted =
    alternatives.find((a) => a.highlighted) || alternatives[0];
  const commonOption: Omit<PlanOption, 'cardDetails' | 'selected'> = {
    type: 'PLAN',
    planData: plan,
    // @TODO: Wire when user can purchase multiple plans
    isBoughtByUser: false,
    allowTrial,
  };

  // Compile some common details between the cards
  type CommonFields =
    | 'title'
    | 'state'
    | 'description'
    | 'coverImg'
    | 'foregroundImg'
    | 'coverImgIsPlaceholder';

  const getDiscountProperties = (
    effectivePlanPrice: EffectivePlanPrice | undefined
  ) => ({
    discountStrip: !!effectivePlanPrice?.discountLabel,
    discountStripTitle: emptyStringIfNull(effectivePlanPrice?.discountLabel),
    discountStripDescription: effectivePlanPrice?.discountDescription,
  });

  const commonDetails: Pick<PlanOption['cardDetails'], CommonFields> = {
    title: plan.name,
    state: 'PURCHASING',
    description: emptyStringIfNull(plan.descriptionShort),
    ...getPlanCoverImage(channel, plan),
  } as const;

  const getPrice = (effectivePlanPrice: EffectivePlanPrice) => {
    const action = effectivePlanPrice.priceBlock.action;
    if (!action) {
      return formatPrice(
        locale,
        effectivePlanPrice.currentPrice,
        effectivePlanPrice.currency
      );
    }
    if (Array.isArray(action)) {
      return formatPrice(locale, action[1], action[0]);
    }
    return action;
  };

  return {
    ...commonOption,
    cardDetails: {
      ...commonDetails,
      ...getDiscountProperties(highlighted),
      planPrices: alternatives.map((alt) => ({
        discount: emptyStringIfNull(alt.priceBlock.discountTag),
        highlighted: true,
        name: periodicityPipe.transform(
          alt.priceBlock.label || alt.periodicity
        ),
        periodicity: alt.periodicity,
        price: getPrice(alt),
        priceRaw: { amount: alt.currentPrice, currency: alt.currency },
        description: emptyStringIfNull(alt.priceBlock.details),
        trialPeriod:
          allowTrial && alt.trialDays
            ? $localize`:@@DataTransformersFreeTrialDays:${alt.trialDays}:trialDays: days free trial period`
            : undefined,
      })),
      listenToManageEvents: false,
      isReactivating: false,
    },
  };
}

export function getPlanCoverImage(
  channel: ChannelWithDocId,
  plan: PlanWithDocId
): Pick<
  PlanOption['cardDetails'],
  'coverImg' | 'foregroundImg' | 'coverImgIsPlaceholder'
> {
  return {
    coverImg: plan.image || channel.branding.bannerImages['3/1'] || '',
    foregroundImg: '',
    coverImgIsPlaceholder: false,
  };
}
