/* istanbul ignore file */
// @TODO: add tests & firebase mocks
import { Auth, User, signInWithCustomToken } from '@angular/fire/auth';
import { ReplaySubject, Subject, filter, lastValueFrom, switchMap } from 'rxjs';
import {
  monitorAuthenticatedUserFingerprint,
  updateUserFingerprint,
} from '@yoimo/client-sdk/users';

import { Firestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { pushToDataLayer } from '@core/utilities';

export type ForcedDisconnectTypes = 'LOGGED_ELSWHERE' | 'SESSION_EXPIRED';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _user$ = new ReplaySubject<User | null>(1);
  readonly user$ = this._user$.asObservable();

  /**
   * Gets triggered whenever the user gets disconnect forcfully.
   */
  private _forcedDisconnect$ = new Subject<ForcedDisconnectTypes>();
  readonly forcedDisconnect$ = this._forcedDisconnect$.asObservable();

  constructor(
    readonly auth: Auth,
    private fs: Firestore
  ) {
    this.auth.onAuthStateChanged((user) => this.setUser(user));
    this.setupUserMonitor();
  }

  private setupUserMonitor() {
    this.user$
      .pipe(
        filter((user): user is User => user !== null),
        switchMap((_) =>
          monitorAuthenticatedUserFingerprint(this.auth, this.fs)
        )
      )
      .subscribe((result) => {
        if (!result) {
          this.auth.signOut().then((_) => {
            this._forcedDisconnect$.next('LOGGED_ELSWHERE');
          });
        }
      });
  }

  /** @deprecated Prefer using the stabilized version, @see user$ */
  get isLoggedIn(): boolean {
    return !!this.auth.currentUser;
  }

  getAuthenticationUrl(
    channelId: string,
    slug: string,
    redirectUrl: string | undefined,
    path?: string
  ): string {
    const targetUrl = new URL('tv', environment.joymo.authUrl);

    if (path) {
      targetUrl.pathname = [targetUrl.pathname, path].join('/');
    }

    targetUrl.searchParams.set('channel_id', channelId);
    targetUrl.searchParams.set('slug', slug);

    if (redirectUrl) {
      targetUrl.searchParams.set('redirect_url', redirectUrl);
    }
    return targetUrl.href;
  }

  async signInWithCustomToken(token: string): Promise<User> {
    const response = await signInWithCustomToken(this.auth, token);
    await lastValueFrom(updateUserFingerprint(this.fs, response.user));
    return response.user;
  }

  getProviderIds(): string[] | null {
    if (!this.auth.currentUser) {
      return null;
    }
    return this.auth.currentUser.providerData.map(
      (provider) => provider.providerId
    );
  }

  /**
   * indicates whether or not the user authentication state has changed
   * @param prevUser previous user
   * @returns true or false if user authentication state has changed
   */
  hasUserAuthStateChanged(prevUser: User | null): boolean {
    return prevUser?.uid !== this.auth.currentUser?.uid;
  }

  setUser(user: User | null): void {
    pushToDataLayer<'authentication'>('authentication', {
      provider: user?.providerData.map((p) => p.providerId).join(),
    });
    this._user$.next(user);
  }
}
