<yo-modal [title]="dialogTitle || stepTitles[processStep]" #yoModal>
  <section *ngIf="channel$ | async as channel" yo-modal-content>
    <div
      *ngIf="isSubscriptionPaused"
      class="flex gap-12 flex-align-start m-b-24"
    >
      <yo-icon icon="information"></yo-icon>
      <p>
        <span i18n="@@subscriptionPausedMessage" class="text-md"
          >You can also reactivate your subscription to watch this video.</span
        ><br />
        <a
          [routerLink]="[homepageUrl, 'profile', 'subscriptions']"
          class="clickable text-md text-semibold"
          i18n="@@goToMySubscriptionsLink"
          >Go to my subscriptions</a
        >
      </p>
    </div>

    <p
      *ngIf="!(user$ | async) && processStep === 'TICKET_SELECT'"
      class="flex gap-12 flex-align-start"
    >
      <yo-icon icon="information"></yo-icon>
      <span class="text-md" i18n="@@ticketOptionsSignInLink"
        >If you already have a ticket or subscription, please
        <a class="text-link" (click)="goToAuthenticationUrl(channel)"
          >sign in to your account</a
        ></span
      >
    </p>

    <ng-container [ngSwitch]="processStep">
      <ng-container *ngSwitchCase="'TICKET_SELECT'">
        <yo-grid class="block p-y-16" [centerIfPossible]="true">
          <li
            yo-grid-item
            *ngFor="let option of subscriptionOptions$ | async; index as i"
          >
            <yo-subscription-ticket-card
              [title]="option.cardDetails.title"
              [coverImg]="option.cardDetails.coverImg"
              [state]="option.cardDetails.state"
              [foregroundImg]="option.cardDetails.foregroundImg"
              [coverImgIsPlaceholder]="option.cardDetails.coverImgIsPlaceholder"
              [description]="option.cardDetails.description"
              [billingDetailsLabel]="option.cardDetails.billingDetailsLabel"
              [renewsOnLabel]="option.cardDetails.renewsOnLabel"
              [manageLink]="option.cardDetails.manageLink"
              [manageLabel]="option.cardDetails.manageLabel"
              [discountStrip]="option.cardDetails.discountStrip"
              [discountStripTitle]="option.cardDetails.discountStripTitle"
              [discountStripDescription]="
                option.cardDetails.discountStripDescription
              "
              [planPrices]="option.cardDetails.planPrices"
              (onPlanClick)="
                onOptionSelected(
                  channel,
                  option,
                  option.cardDetails.planPrices[$event].periodicity
                )
              "
            >
            </yo-subscription-ticket-card>
          </li>
          <li
            yo-grid-item
            *ngFor="let option of ticketOptions$ | async; index as i"
          >
            <yo-ticket-card
              [title]="option.cardDetails.title"
              [coverImg]="option.cardDetails.coverImg"
              [coverImgIsPlaceholder]="option.cardDetails.coverImgIsPlaceholder"
              [foregroundImg]="option.cardDetails.foregroundImg"
              [state]="option.cardDetails.state"
              [description]="option.cardDetails.description"
              [validityOrExpiryInfo]="option.cardDetails.validityOrExpiryInfo"
              [toProductListLinkLabel]="
                option.cardDetails.toProductListLinkLabel
              "
              [toProductListLink]="option.cardDetails.toProductListLink"
              [actionButtonLabel]="option.cardDetails.actionButtonLabel"
              (onActionButtonClicked)="onTicketActionClick(channel, option)"
            ></yo-ticket-card>
          </li>
        </yo-grid>
        <ng-container *ngIf="sport80Settings$ | async as sport80Settings">
          <div
            *ngIf="sport80Settings.enabled && (canLinkSport80$ | async)"
            class="sport-80-card p-16 lg:p-20"
          >
            <header class="m-b-12">
              <img [src]="channel.branding.logoImage" [alt]="channel.name" />
            </header>
            <div
              class="flex flex-column md:flex-row flex-justify-between flex-align-start md:flex-align-end gap-12 text-sm"
            >
              <p>{{ sport80Settings.description }}</p>
              <a
                class="text-semibold text-link"
                [routerLink]="[
                  this.homepageUrl,
                  'profile',
                  'integrations',
                  'sport80'
                ]"
                i18n="@@TicketOptionsConnectMembership"
                >Link membership</a
              >
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div *ngSwitchCase="'HANDLE_PAYMENT'" class="p-x-8 md:p-0">
        <yo-button
          *ngIf="!isLockedToSingleTicket"
          role="link"
          (click)="processStep = 'TICKET_SELECT'"
        >
          <yo-icon icon="arrow_left"></yo-icon>
          <span i18n="@@TicketOptionsBackToTicketsLink">Back to tickets</span>
        </yo-button>

        <joymo-ticket-option-selector
          [option]="selectedOption"
          (onUserSelection)="paymentConfig$.next($event)"
        >
        </joymo-ticket-option-selector>
        <ng-container
          *ngIf="
            selectedOption &&
              selectedOption.type === 'TICKET' &&
              selectedOption.state !== 'AVAILABLE';
            else paymentHandlerTmpl
          "
        >
          <joymo-info-block
            class="flex m-t-48"
            type="WARNING"
            [heading]="ticketUnavailableReason[selectedOption.state]"
            size="sm"
          ></joymo-info-block>
        </ng-container>
      </div>
    </ng-container>
  </section>
</yo-modal>

<ng-template #paymentHandlerTmpl>
  <ng-container *ngIf="channel$ | async as channel">
    <joymo-payment-handler
      *ngIf="paymentConfig$ | async as paymentConfig"
      [additionalConfig]="paymentConfig"
      [channelId]="channel.docId"
      [eventId]="eventId"
      [option]="selectedOption"
      [supportEmail]="channel.branding.contactInformation?.supportEmail"
      [videoId]="videoId"
      (onActionResult)="handleActionResult($event)"
    >
    </joymo-payment-handler>
  </ng-container>
</ng-template>
