/* istanbul ignore file */

import { ActivatedRoute, Router } from '@angular/router';

import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { WindowService } from '@yoimo/joymo-ui';
import { ChannelWithDocId, Scope } from '@yoimo/client-sdk/channels';
import { getSoldTickets, UserInfoWithDocId } from '@yoimo/client-sdk/users';
import {
  EMPTY,
  filter,
  first,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
} from 'rxjs';
import { SoldTicket, Ticket, TicketPrice } from '@yoimo/interfaces';
import { EventWithDocId } from '@yoimo/client-sdk/events';
import { Firestore } from '@angular/fire/firestore';
import { EventsService } from './events.service';
import { PagedQueryCommand } from '@yoimo/client-sdk/base';
import {
  getTicketExpiry,
  isSoldTicketStillValid,
  TicketExpiry,
} from '@yoimo/client-sdk/business-logic';
import { TicketCardDetails } from '../../features/ticket-options/ticket-options-types';
import {
  getExpiryTimeLabel,
  ticketToTicketOption,
} from '../../features/ticket-options/data-transformers';
import { DatePipe } from '@angular/common';
import { HOUR } from '@core/utilities/utils';

enum TicketQueryParams {
  TICKET_ID = 'selectedTicketId',
  TICKET_ORIGIN = 'ticketDialogOrigin',
  TICKET_PERIOD = 'ticketPeriod',
}

export type TicketEntry = {
  soldTicket: SoldTicket;
  associatedTicket: Ticket;
  associatedEvent: EventWithDocId;
};

@Injectable({ providedIn: 'root' })
export class TicketsService {
  constructor(
    private route: ActivatedRoute,
    private windowService: WindowService,
    private router: Router,
    private fs: Firestore,
    private eventService: EventsService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  /**
   * @returns Observable of the selected ticket ID
   * @remarks The promise will only resolve if a ticket was found. Otherwise, nothing happens.
   */
  getPreselectedTicket$(): Observable<string> {
    return this.route.queryParams.pipe(
      map((data) => data[TicketQueryParams.TICKET_ID]),
      first(Boolean)
    );
  }

  /**
   * Create an URL pointing to a specific ticket option
   */
  getPreSelectionUrl(optionId: string): string {
    const preselectionUrl = new URL(this.windowService.document.location.href);
    preselectionUrl.searchParams.set(TicketQueryParams.TICKET_ID, optionId);
    return preselectionUrl.href;
  }

  /**
   * Clear ticket-specific query parameters, keeps the rest
   */
  async clearTicketQueryParams(): Promise<void> {
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams: Object.values(TicketQueryParams).reduce(
        (obj, key) => ({
          ...obj,
          [key]: null,
        }),
        {}
      ),
    });
  }

  /**
   *
   * @param scope Scope
   * @param userInfo UserInfo
   * @param pager$ A pager for the query
   * @param destroy$ A limiter to know when to complete the stream
   * @param valid a boolean to determine if we want valid or expired tickets
   * @returns A stream of TicketEntry array
   */
  fetchTickets(
    scope: Scope,
    userInfo: UserInfoWithDocId,
    pager$: Subject<PagedQueryCommand>,
    destroy$: Subject<any>,
    valid: boolean = true
  ): Observable<TicketEntry[]> {
    return this.getTicketEntriesForUser(scope, userInfo, pager$, destroy$).pipe(
      map((tickets) =>
        tickets.filter((ticketEntry) => {
          return (
            valid ===
            isSoldTicketStillValid(
              ticketEntry.soldTicket,
              ticketEntry.associatedEvent,
              ticketEntry.associatedTicket.scope,
              undefined
            )
          );
        })
      )
    );
  }

  getSoldTickets(
    scope: Scope,
    userInfo: UserInfoWithDocId,
    pager$: Subject<PagedQueryCommand>
  ): Observable<SoldTicket[]> {
    return getSoldTickets(this.fs, userInfo.docId, {
      scope: scope.scope,
      scopeId: scope.scopeId,
      pager: pager$.pipe(startWith('init' as const)),
    }).pipe(map((pagedQueryResult) => pagedQueryResult.data));
  }

  getTicketEntriesForUser(
    scope: Scope,
    userInfo: UserInfoWithDocId,
    pager$: Subject<PagedQueryCommand>,
    destroy$: Subject<any>
  ): Observable<TicketEntry[]> {
    return this.getSoldTickets(scope, userInfo, pager$).pipe(
      switchMap((soldTickets) => {
        // No tickets? ready to display
        if (soldTickets.length < 1) {
          return of([]);
        }

        // store them to re-use in another pipe
        let currentSoldTickets = soldTickets;
        // get unique event ids
        const uniqueEventIds = [
          ...new Set(soldTickets.map((st) => st.eventId)),
        ];
        // Fetch all events related to those SoldTickets
        return this.eventService
          .getEvents$({ eventIds: uniqueEventIds }, scope, destroy$)
          .pipe(
            map((events) => {
              // Get tickets from SoldTicket using the event

              const tickets = currentSoldTickets
                .map((st) => {
                  const eventAssociatied = events.find(
                    (e) => e.docId === st.eventId
                  );
                  const ticket = eventAssociatied?.tickets.find(
                    (t) => t.id == st.ticketId
                  );
                  return ticket;
                })
                .filter((t): t is Ticket => !!t);

              return currentSoldTickets.map((st) => {
                // With the filter before-hand, we know these variables are going to be defined
                const associatedEvent = events.find(
                  (e): e is EventWithDocId => e.docId == st.eventId
                ) as EventWithDocId;
                const associatedTicket = tickets.find(
                  (t) => t.id === st.ticketId
                ) as Ticket;

                return {
                  soldTicket: st,
                  associatedTicket,
                  associatedEvent,
                };
              });
            })
          );
      })
    );
  }

  transformTicketEntryToTicketCardDetails(
    ticketEntry: TicketEntry,
    currentChannel: ChannelWithDocId,
    homepageUrl: string
  ): TicketCardDetails {
    const { soldTicket, associatedTicket, associatedEvent } = ticketEntry;
    let cardDetails: Partial<TicketCardDetails> = {
      ...this.getProductsCardDetails(
        homepageUrl,
        associatedEvent,
        associatedTicket
      ),
    };

    if (associatedTicket.scope) {
      cardDetails.toProductListLink = undefined;
      cardDetails.actionButtonLabel = $localize`:@@ticketCardShowStreamsLink:Show streams`;
      cardDetails.purchasedActionLink = [
        homepageUrl,
        'events',
        associatedEvent.docId,
        'tickets',
        associatedTicket.id,
      ];
    }

    const hasRedemptionLimit = associatedTicket.scope?.redemptionLimit === 1;
    if (hasRedemptionLimit) {
      cardDetails = {
        ...cardDetails,
        ...this.getCardDetailsIfTicketHasRedemption(homepageUrl, soldTicket),
      };
    }

    const ticketExpiry = getTicketExpiry(
      associatedTicket.scope,
      associatedEvent
    );
    cardDetails = {
      ...cardDetails,
      ...this.handleTicketExpiry(ticketExpiry, soldTicket),
    };

    const ticketCardDetail = {
      ...ticketToTicketOption(
        currentChannel,
        associatedTicket,
        associatedEvent,
        undefined,
        this.locale,
        homepageUrl || ''
      ).cardDetails,
      state: 'PURCHASED',
      ...cardDetails,
    } as TicketCardDetails;

    return ticketCardDetail;
  }

  getProductsCardDetails(
    homepageUrl: string,
    event: EventWithDocId,
    ticket: Ticket
  ): Partial<TicketCardDetails> {
    const partialCard = {
      toProductListLink: [
        homepageUrl || '',
        'events',
        event.docId,
        'tickets',
        ticket.id,
      ],
      toProductListLinkLabel: $localize`:@@ticketCardShowStreamsLink:Show streams`,
      actionButtonLabel: $localize`:@@ticketCardGoToEventLink:Go to the event page`,
      purchasedActionLink: [homepageUrl || '', 'events', event.docId],
    };

    return partialCard;
  }

  getCardDetailsIfTicketHasRedemption(
    homepageUrl: string,
    soldTicket: SoldTicket
  ): Partial<TicketCardDetails> {
    const details: Partial<TicketCardDetails> = {};
    if (soldTicket.videoIdsAccessed.length) {
      details.actionButtonLabel = $localize`:@@ticketCardGoToStreamLink:Go to this stream`;
      details.purchasedActionLink = [
        homepageUrl || '',
        'videos',
        soldTicket.videoIdsAccessed[0],
      ];
    }
    return {
      title: $localize`:@@ticketCardOneStreamTicketTitle:One-stream ticket`,
      toProductListLink: undefined,
      description: '',
      ...details,
    };
  }

  handleTicketExpiry(
    ticketExpiry: TicketExpiry,
    soldTicket: SoldTicket
  ): Partial<TicketCardDetails> {
    let expiryDate;
    let validityAfterActivated = '';
    if (ticketExpiry.type == 'RELATIVE' && !!soldTicket.redemptionTime) {
      const relativeExpiryDate = new Date(
        soldTicket.redemptionTime.getTime() + ticketExpiry.hours * HOUR
      );
      expiryDate = this.formatExpiryDate(this.locale, relativeExpiryDate);
      validityAfterActivated = $localize`:@@ticketCardTicketExpiryDate:Expires: ${expiryDate}:date:`;
    } else if (ticketExpiry.type == 'RELATIVE' && !soldTicket.redemptionTime) {
      validityAfterActivated = getExpiryTimeLabel(ticketExpiry.hours);
    } else if (ticketExpiry.type == 'ABSOLUTE') {
      expiryDate = this.formatExpiryDate(this.locale, ticketExpiry.date);
      validityAfterActivated = $localize`:@@ticketCardTicketExpiryDate:Expires: ${expiryDate}:date:`;
    }

    return { validityOrExpiryInfo: validityAfterActivated };
  }

  formatExpiryDate(locale: string, date: Date): string {
    let format = 'MMM d';
    if (new Date().getUTCFullYear() !== date.getUTCFullYear()) {
      format += ', y';
    }

    const datePipe = new DatePipe(locale);
    return `${datePipe.transform(date, format)} ${datePipe.transform(
      date,
      'HH:mm'
    )}`;
  }

  static getCheapestAccess(tickets: Ticket[]): TicketPrice | undefined {
    const lowestTicketPrice = (tp1: TicketPrice, tp2: TicketPrice) =>
      tp1.price - tp2.price;
    const lowestTicketPrices = tickets.map(
      (t) => t.prices.sort(lowestTicketPrice)[0]
    );
    return lowestTicketPrices.sort(lowestTicketPrice)[0];
  }
}
