import { getDocData, getDocData$, collectionRef } from '../base/collection.js';
import { map, takeUntil, take, Subject, merge, filter } from 'rxjs';
import '@angular/fire/functions';
import 'rxjs/operators';
import { execQuery, pagedQuery, batchedInQuery } from '../base/query.js';
import { converter } from './VideoDataConverter.js';

function VIDEOS_COLLECTION(channelId) {
  return `channels/${channelId}/videos`;
}
function colRef(fs, channelId) {
  return collectionRef(VIDEOS_COLLECTION(channelId), fs, converter());
}
const DEFAULT_PAGE_LIMIT = 10;
function getVideo(fs, videoId, channelId) {
  if (!videoId.includes(":")) {
    return getDocData(videoId, colRef(fs, channelId));
  } else {
    const where = ["externalIds", "array-contains", videoId];
    const queryOptions = { qLimit: 1 };
    return execQuery(colRef(fs, channelId), where, queryOptions).pipe(
      map((results) => results[0])
    );
  }
}
function getVideo$(fs, id, channelId, until$) {
  return getDocData$(id, colRef(fs, channelId)).pipe(takeUntil(until$));
}
function getVideosFromSource(fs, source, options) {
  if (options.scope === "ORGANIZATION") {
    throw new Error("Scope other than CHANNEL is not supported");
  }
  const col = colRef(fs, options.scopeId);
  const listQuery = "videoIds" in source ? queryStaticSourceList(col, source) : queryDynamicSourceList(col, source, options);
  return listQuery.pipe(take(1));
}
function getVideosFromSourcePaged(fs, source, options) {
  if (options.scope === "ORGANIZATION") {
    throw new Error("Scope other than CHANNEL is not supported");
  }
  const col = colRef(fs, options.scopeId);
  const [wheres, qOptions] = getQueryParamsFromDynamicSourceList(source, options);
  return pagedQuery(col, wheres, options.pager, qOptions);
}
function queryStaticSourceList(col, source) {
  return batchedInQuery(col, source.videoIds).pipe(
    map((videos) => videos.filter((v) => v !== void 0))
  );
}
function maxDate(a, b) {
  return a < b ? b : a;
}
function minDate(a, b) {
  return a < b ? a : b;
}
function getDateRangeForQuery(options) {
  if (options.dateRange && options.sort && !["DATE_TRIMMED", "DATE"].includes(options.sort[0])) {
    throw new TypeError("A date range is not supported when using sort strategy: " + options.sort[0]);
  }
  const nowRef = options._nowRef || /* @__PURE__ */ new Date();
  const actualDateRange = options.dateRange?.map((d) => d === "NOW" ? nowRef : d) || [null, null];
  if (options.sort && options.sort[0] === "DATE_TRIMMED") {
    if (options.sort[1] === "ASC") {
      actualDateRange[0] = options.dateRange && options.dateRange[0] instanceof Date ? maxDate(options.dateRange[0], nowRef) : nowRef;
    } else {
      actualDateRange[1] = options.dateRange && options.dateRange[1] instanceof Date ? minDate(options.dateRange[1], nowRef) : nowRef;
    }
  }
  return [actualDateRange[0], actualDateRange[1]];
}
function getWhereParamsFromDynamicSourceList(source, options) {
  const wheres = [];
  if (source.eventId) {
    wheres.push(["eventId", "==", source.eventId]);
  }
  if (source.tags) {
    if (source.tags.kind === "ALL") {
      throw new TypeError("Source list with tags kind ALL is not supported yet!");
    }
    wheres.push(["tags", "array-contains-any", source.tags.list]);
  }
  if (source.attributes) {
    for (const attr of ["attr0", "attr1", "attr2", "attr3"]) {
      if (attr in source.attributes) {
        wheres.push([attr, "==", source.attributes[attr]]);
      }
    }
  }
  if (!options.includeArchived) {
    wheres.push(["archived", "==", false]);
  }
  if (!options.includeUnpublished) {
    wheres.push(["published", "==", true]);
  }
  if (source.streamState) {
    wheres.push(["streamState", "==", source.streamState]);
  }
  if (source.type) {
    wheres.push(["type", "==", source.type]);
  }
  const dateRange = getDateRangeForQuery(options);
  if (dateRange[0]) {
    wheres.push(["uploadTime", ">=", dateRange[0]]);
  }
  if (dateRange[1]) {
    wheres.push(["uploadTime", "<=", dateRange[1]]);
  }
  return wheres;
}
function getOptionsParamsFromDynamicSourceList(source, options) {
  const qOptions = {};
  qOptions.qLimit = options.limit || DEFAULT_PAGE_LIMIT;
  if (options.sort) {
    const fieldMapping = {
      "VIEWS": "views",
      "DATE": "uploadTime",
      "POPULARITY": "popularity",
      "DATE_TRIMMED": "uploadTime"
    };
    qOptions.qOrderBy = [{
      field: fieldMapping[options.sort[0]],
      sort: options.sort[1] === "ASC" ? "asc" : "desc"
    }];
  }
  return qOptions;
}
function getQueryParamsFromDynamicSourceList(source, options) {
  return [
    getWhereParamsFromDynamicSourceList(source, options),
    getOptionsParamsFromDynamicSourceList(source, options)
  ];
}
function queryDynamicSourceList(col, source, options) {
  const [wheres, qOptions] = getQueryParamsFromDynamicSourceList(source, options);
  return execQuery(col, wheres, qOptions);
}
function getLiveAndUpcomingStreams(fs, source, options) {
  if (options.scope === "ORGANIZATION") {
    throw new Error("Scope other than CHANNEL is not supported");
  }
  const col = colRef(fs, options.scopeId);
  const [wheres, qOptions] = getQueryParamsFromDynamicSourceList(
    {
      ...source,
      type: "STREAM"
    },
    {
      ...options,
      sort: ["DATE_TRIMMED", "ASC"],
      _nowRef: new Date(Date.now() - 12 * 60 * 60 * 1e3)
    }
  );
  const subject = new Subject();
  const pagerOverride = merge(options.pager, subject);
  return pagedQuery(col, wheres, pagerOverride, qOptions).pipe(
    map((result) => {
      const newData = result.data.filter((v) => v.streamState !== "FINISHED");
      if (newData.length < (options.limit || DEFAULT_PAGE_LIMIT) && result.paging.canLoadNext) {
        subject.next("next");
        return null;
      }
      subject.complete();
      return {
        data: newData,
        paging: result.paging
      };
    }),
    filter((t) => t !== null)
  );
}

export { getLiveAndUpcomingStreams, getVideo, getVideo$, getVideosFromSource, getVideosFromSourcePaged };

