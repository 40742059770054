import 'rxjs/operators';

function isDefined(element) {
  return element !== void 0;
}
async function hashString(input) {
  const text = new TextEncoder().encode(input);
  return new Uint8Array(await crypto.subtle.digest("SHA-1", text));
}

export { hashString, isDefined };

