import { declareCallable } from '../base/callable.js';
import '@angular/fire/firestore';
import 'rxjs';
import 'rxjs/operators';

let cachedCallable;
function _cachedCallable(ff) {
  if (!cachedCallable) {
    cachedCallable = declareCallable("payments-managesubscription", ff);
  }
  return cachedCallable;
}
function cancelSubscription(ff, channelId, subscriptionId, reason) {
  return _cachedCallable(ff)({
    channelId,
    subscriptionId,
    command: {
      type: "CANCEL",
      reason: reason.substring(0, 300)
    }
  });
}
function uncancelSubscription(ff, channelId, subscriptionId) {
  return _cachedCallable(ff)({
    channelId,
    subscriptionId,
    command: {
      type: "UNCANCEL"
    }
  });
}
function updateSubscriptionPaymentMethod(ff, channelId, subscriptionId, newPaymentMethodId, chargeImmediately) {
  return _cachedCallable(ff)({
    channelId,
    subscriptionId,
    command: { type: "UPDATE_PAYMENT_METHOD", newPaymentMethodId, chargeImmediately }
  });
}
function pauseSubscription(ff, channelId, subscriptionId, duration) {
  const d = typeof duration === "number" ? duration : { date: duration.getTime() };
  return _cachedCallable(ff)({
    channelId,
    subscriptionId,
    command: { type: "PAUSE", duration: d }
  });
}
function unpauseSubscription(ff, channelId, subscriptionId) {
  return _cachedCallable(ff)({
    channelId,
    subscriptionId,
    command: { type: "UNPAUSE" }
  });
}

export { cancelSubscription, pauseSubscription, uncancelSubscription, unpauseSubscription, updateSubscriptionPaymentMethod };

