const MONTH_MULTIPLIER = 0.032855;
const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;

/**
 * @returns a localized date into the `DD MMMM YYYY` format
 * @param date Date to be formatted
 * @param locale Locale to use for formatting
 * @example getDateString(new Date(2022,1,24), 'en')
 * // returns "24 January 2022"
 */
export function getDateString(
  date: Date,
  locale: string,
  monthLength: 'long' | 'short' = 'long'
): string {
  return `${date.getDate()} ${date.toLocaleString(locale, {
    month: monthLength,
    year: 'numeric',
  })}`;
}

/**
 * Convert days to months
 * @param period number of days
 * @returns number of months
 */
export function getMonths(period: number): number {
  return Math.round(period * MONTH_MULTIPLIER);
}

/** convert milliseconds into days */
export function getDaysFromMilliseconds(time: number): number {
  return time / MILLISECONDS_IN_A_DAY;
}
