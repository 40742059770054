import { declareCallable } from '../base/callable.js';
import '@angular/fire/firestore';
import 'rxjs';
import 'rxjs/operators';

let cachedCallable;
function linkExternalSAMLAccount(ff, channelId, returnUrl, providerId) {
  if (!cachedCallable) {
    cachedCallable = declareCallable("saml-link", ff);
  }
  return cachedCallable({
    channelId,
    providerType: "SAML",
    providerId,
    returnUrl,
    operation: "LINK"
  });
}
function unlinkExternalSAMLAccount(ff, channelId, returnUrl, providerId) {
  if (!cachedCallable) {
    cachedCallable = declareCallable("saml-link", ff);
  }
  return cachedCallable({
    channelId,
    providerType: "SAML",
    providerId,
    returnUrl,
    operation: "UNLINK"
  });
}

export { linkExternalSAMLAccount, unlinkExternalSAMLAccount };

