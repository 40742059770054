import { arrayUnion } from '@angular/fire/firestore';
import { updateUserDoc } from './crud.js';
import { of } from 'rxjs';

function filterOnboardings(featureIds, info) {
  if (!info) {
    return of([...featureIds]);
  }
  if (info.onboardingStatus && info.onboardingStatus.skipTutorial) {
    return of([]);
  }
  const featuresSet = new Set(info.onboardingStatus && info.onboardingStatus.completed ? info.onboardingStatus.completed : []);
  return of(featureIds.filter((f) => !featuresSet.has(f)));
}
function saveOnboardingStatus(fs, userId, _userInfo, featureIds) {
  return updateUserDoc(fs, userId, {
    // @ts-expect-error the type UpdateData<T> is not able to infer the combined sub keys unless they are inlined
    // UpdateData<{a:string, b: {c: string}}> will correctly accept an update of the form {"b.c": "something"}
    // but will fail with this equivalent type:
    // type B = {c:string}; UpdateData<{a:string, b:B}>
    "onboardingStatus.completed": arrayUnion(...featureIds)
  });
}

export { filterOnboardings, saveOnboardingStatus };

