function parseUrls(text, baseUrl) {
  const linkMatcher = /\[([^\]]+?)\]\(([^)]+?)\)/gm;
  const pushOrMerge = (array, newElement) => {
    const canMergeToPrevious = typeof newElement === "string" && typeof array[array.length - 1] === "string";
    if (canMergeToPrevious) {
      array[array.length - 1] = array[array.length - 1] + newElement;
    } else {
      array.push(newElement);
    }
  };
  const result = [];
  let rxResult;
  let lastIndex = 0;
  while ((rxResult = linkMatcher.exec(text)) !== null) {
    if (rxResult.index !== lastIndex) {
      result.push(text.substring(lastIndex, rxResult.index));
    }
    lastIndex = linkMatcher.lastIndex;
    try {
      const targetUrl = new URL(rxResult[2], baseUrl);
      if (targetUrl.protocol === "https:") {
        pushOrMerge(result, {
          type: "URL",
          target: targetUrl,
          text: rxResult[1]
        });
      } else {
        pushOrMerge(result, rxResult[1]);
      }
    } catch (e) {
      pushOrMerge(result, rxResult[1]);
    }
  }
  if (lastIndex !== 0) {
    pushOrMerge(result, text.substring(lastIndex));
  }
  return result.length ? result : void 0;
}
function semanticParsing(text, baseUrl, options = { parseUrls: true }) {
  const input = typeof text === "string" ? [text] : text;
  return input.flatMap((t) => {
    if (typeof t !== "string")
      return t;
    if (options.parseUrls) {
      return semanticParsing(parseUrls(t, baseUrl) || t, baseUrl, { ...options, parseUrls: false });
    } else {
      return t;
    }
  });
}

export { semanticParsing };

