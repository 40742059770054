import '@angular/fire/functions';
import 'rxjs';
import 'rxjs/operators';
import '@angular/fire/firestore';
import { cacheConverter, DefaultDataConverter } from '../base/DefaultDataConverter.js';
import { UserInfo } from '@yoimo/interfaces/validators';

class UserInfoConverter extends DefaultDataConverter {
  constructor() {
    super(UserInfo);
  }
}
const converter = cacheConverter(UserInfoConverter);

export { UserInfoConverter, converter };

