import { declareCallable } from '../base/callable.js';
import '@angular/fire/firestore';
import 'rxjs';
import 'rxjs/operators';

let cachedCallable;
function saveFeedback(ff, feedback) {
  if (!cachedCallable) {
    cachedCallable = declareCallable("users-savefeedback", ff);
  }
  return cachedCallable(feedback);
}

export { saveFeedback };

