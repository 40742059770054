import '@angular/fire/functions';
import 'rxjs';
import 'rxjs/operators';
import '@angular/fire/firestore';
import { cacheConverter, DefaultDataConverter } from '../base/DefaultDataConverter.js';
import { UserConsentEntry } from '@yoimo/interfaces/validators';

class UserConsentEntryDataConverter extends DefaultDataConverter {
  constructor() {
    super(UserConsentEntry);
  }
}
const converter = cacheConverter(UserConsentEntryDataConverter);

export { UserConsentEntryDataConverter, converter };

