import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ChannelWithDocId, Scope } from '@yoimo/client-sdk/channels';
import { Observable, ReplaySubject, catchError, filter, map, of } from 'rxjs';

import { LibScopeService } from 'shared-lib/core';

@Injectable({ providedIn: 'root' })
export class ScopeService implements LibScopeService {
  private _scope?: Scope;
  public scope$: ReplaySubject<Scope> = new ReplaySubject<Scope>(1);

  constructor(private router: Router) {}

  /**
   * @returns The Scope object as assigned by `setScope` during the resolve cycle
   * @throws If the scope is accessed before assignment
   */
  getScope(redirectIfNotFound?: boolean): Scope | never {
    if (!this._scope) {
      if (redirectIfNotFound)
        this.router.navigate(['/404'], { skipLocationChange: true });
      throw new Error('Scope not found');
    }
    return this._scope;
  }

  /**
   * Saves the scope to the class state.
   * @remarks Should only be called when first resolving the scope
   */
  setScope(scope?: Scope): void {
    if (!scope) {
      this.scope$.error('Scope cannot be undefined');
      return;
    }

    // Do not trigger updates unless scope is different
    if (this._scope && !this.scopeIsDifferent(scope)) {
      return;
    }

    this._scope = scope;
    this.scope$.next(this.getScope());
  }

  getChannel(): ChannelWithDocId {
    if (this._scope?.scope !== 'CHANNEL') {
      throw new Error('Scope is not a channel');
    }
    return this._scope.channel;
  }

  getChannelFromScope(scope: Scope): ChannelWithDocId {
    if (scope.scope !== 'CHANNEL') {
      throw new Error('Scope is not a channel');
    }
    return scope.channel;
  }

  listenToChannel(): Observable<ChannelWithDocId | never> {
    return this.scope$.pipe(
      catchError((err, caught) => {
        return of(undefined);
      }),
      filter((scope) => this.scopeIsDefined(scope)),
      map((scope) => this.getChannelFromScope(scope!))
    );
  }

  getOrganization(): never {
    throw new Error('NotImplemented');
  }

  /**
   * Get the main URL ("home") for the current scope.
   *
   * @deprecated Prefer a reactive version instead
   * @returns A routerLink-ready string pointing to the home page
   */
  getHomepageUrl(): string | never {
    if (this._scope?.scope === 'CHANNEL') {
      return this.getChannelUrl();
    }
    return this.getOrganizationUrl();
  }

  // TODO: Will have to look into edge cases where domain & slug exists, we might end up not wanting the slug appended if already on a channel's domain.
  getHomepageUrlFromScope(scope: Scope): string | never {
    if (scope.scope === 'CHANNEL') {
      return this.getChannelUrlFromChannel(this.getChannelFromScope(scope));
    }

    return this.getOrganizationUrl();
  }
  getChannelUrl(): string {
    const { slug = '' } = this.getChannel();
    return '/' + slug;
  }
  getChannelUrlFromChannel(channel: ChannelWithDocId): string {
    const { slug = '' } = channel;
    return '/' + slug;
  }
  getOrganizationUrl(): never {
    throw new Error('NotImplemented');
  }

  /**
   * @param scope A given scope
   * @returns the domain for and organization or a channel
   */
  getDomainFromScope(scope: Scope): string | null | never {
    return scope.scope === 'CHANNEL'
      ? this.getDomainFromChannel(this.getChannelFromScope(scope))
      : this.getDomainFromOrganization();
  }
  getDomainFromChannel(channel: ChannelWithDocId): string | null {
    return channel.domain;
  }
  getDomainFromOrganization(): never {
    throw new Error('NotImplemented');
  }

  scopeIsDefined(scope?: Scope): scope is Scope {
    return scope !== undefined && scope.scope !== undefined;
  }

  scopeIsDifferent(scope: Scope): boolean {
    return this._scope !== undefined && scope.scopeId !== this._scope.scopeId;
  }
}
