/* istanbul ignore file */

import { Density, Size } from '@yoimo/joymo-ui';

import { AbstractListModule } from '@yoimo/interfaces';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LayoutService {
  readonly densitySizeMap: { [key in Density]: Size } = {
    normal: 'lg',
    dense: 'md',
    densest: 'sm',
  };

  density(size: AbstractListModule['density']): Density {
    return size.toLowerCase() as Density;
  }
}
