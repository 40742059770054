import '@angular/fire/functions';
import 'rxjs';
import 'rxjs/operators';
import '@angular/fire/firestore';
import { cacheConverter, DefaultDataConverter } from '../base/DefaultDataConverter.js';
import { Event } from '@yoimo/interfaces/validators';

class EventDataConverter extends DefaultDataConverter {
  constructor() {
    super(Event);
  }
}
const converter = cacheConverter(EventDataConverter);

export { EventDataConverter, converter };

