import { PopoverOptions } from '@yoimo/joymo-ui';
import { Feature } from 'shared-lib/core';

export interface PopoverAction {
  label: string;
  onClick: () => void;
}
export interface Popover extends PopoverOptions {
  feature: Feature;
  title: string;
  description: string;
}

export interface PopoverWithAction extends Popover {
  actions: PopoverAction[];
}

export const featureTexts: Record<
  Feature,
  {
    title: string;
    description: string;
  }
> = {
  PROFILE: {
    title: $localize`:@@onboardingTitleProfile:Profile menu`,
    description: $localize`:@@onboardingDescriptionProfile:Quick access to your tickets, subscription management, and personal settings.`,
  },
  ATTRIBUTES: {
    title: $localize`:@@onboardingTitleAttributes:Explore effortlessly`,
    description: $localize`:@@onboardingDescriptionAttributes:Use filters to list streams according to events, dates, and other parameters.`,
  },
  PAGES: {
    title: $localize`:@@onboardingTitlePages:Navigation Bar`,
    description: $localize`:@@onboardingDescriptionPages:Check in here for Calendar, Events and more.`,
  },
  NOTIFICATIONS: {
    title: $localize`:@@onboardingTitleNotifications:Notifications`,
    description: $localize`:@@onboardingDescriptionNotifications:Get updates for the events you follow and stay connected to the latest streams without missing a beat.`,
  },
  FOLLOW: {
    title: $localize`:@@onboardingTitleFollow:Follow`,
    description: $localize`:@@onboardingDescriptionFollow:Hit the follow button to get notified whenever new streams happen.`,
  },
};
