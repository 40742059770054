import { hashString } from './util.js';

async function getUserTestingGroup(userInfo, testFactor, numberOfGroups, experimentId) {
  if (Math.random() > testFactor) {
    return null;
  }
  return await getStableBucket(numberOfGroups, experimentId + userInfo.docId);
}
async function getStableBucket(numBuckets, input) {
  const hashed = await hashString(input);
  let rem = 0;
  for (const digit of hashed) {
    rem = (rem * 256 + digit) % numBuckets;
  }
  return rem;
}

export { getStableBucket, getUserTestingGroup };

