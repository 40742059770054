import { VideoWithDocId } from '@yoimo/client-sdk/videos';
import { getDateString } from './dates';

export async function copyToClipboard(
  currentWindow: Window | null,
  document: Document,
  text: string
): Promise<void> {
  const clipboard = currentWindow?.navigator.clipboard;

  if (clipboard) {
    await clipboard.writeText(text);
    return;
  }

  const textArea = document.createElement('textarea');
  textArea.innerText = text;
  textArea.select();
  document.execCommand('copy');
  textArea.remove();
}

export function defaultVideoCategorizer(
  locale: string
): (video: VideoWithDocId) => ReturnType<VideoCategorizer> {
  return function _defaultCategorizerImpl_(video) {
    return {
      key: video.uploadTime.toDateString(),
      label: getDateString(video.uploadTime, locale),
    };
  };
}

export type VideoCategorizer = (video: VideoWithDocId) => {
  key: string;
  label: string;
};

// TODO: fix to eliminate manual link creation
export function getMailToLink(
  email: string,
  subject?: string,
  body?: string
): string {
  let link = `mailto:${email}`;
  if (subject) {
    link += `?subject=${subject}`;
  }
  if (body) {
    link += `&body=${body}`;
  }
  return link;
}
