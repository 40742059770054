import { getActivePage as getActivePage$1 } from '../pages/utils.js';
import 'rxjs';
import '@angular/fire/firestore';
import '@angular/fire/functions';
import 'rxjs/operators';
import '@yoimo/interfaces/validators';

function getActivePage(event) {
  return getActivePage$1(event.pages);
}

export { getActivePage };

