import '@angular/fire/functions';
import 'rxjs';
import 'rxjs/operators';
import '@angular/fire/firestore';
import { cacheConverter, DefaultDataConverter } from '../base/DefaultDataConverter.js';
import { Video } from '@yoimo/interfaces/validators';

class VideoDataConverter extends DefaultDataConverter {
  constructor() {
    super(Video);
  }
}
const converter = cacheConverter(VideoDataConverter);

export { VideoDataConverter, converter };

