import { Firestore } from '@angular/fire/firestore';

import { map, Observable } from 'rxjs';

import {
  getChannelPublicSettings,
  getExternalPlanProviders,
} from '@yoimo/client-sdk/channels';
import { UserInfoWithDocId } from '@yoimo/client-sdk/users';
import { Sport80ExternalInfo, Sport80PlanProvider } from '@yoimo/interfaces';

export function getSport80Settings$(
  fs: Firestore,
  channelId: string,
  userId: string,
  mustBeEnabled?: boolean
): Observable<Sport80PlanProvider | undefined> {
  return getChannelPublicSettings(fs, channelId).pipe(
    map((s) => getExternalPlanProviders(s, userId)),
    map((externalProviders) => {
      const sport80 = externalProviders.find(
        (p) => p.id === 'sport80' && p.enabled
      );
      if (!sport80 && mustBeEnabled) throw new Error('Sport80MustBeDefined');
      return sport80;
    })
  );
}

export function getUserSport80Account(
  channelId: string,
  userInfo: UserInfoWithDocId
): Sport80ExternalInfo | undefined {
  if (!userInfo?.externalIntegrationsData?.payment) return undefined;
  if (!userInfo.externalIntegrationsData.payment['sport80']) return undefined;

  return userInfo.externalIntegrationsData.payment.sport80[channelId];
}
