import { declareCallable } from '../base/callable.js';
import '@angular/fire/firestore';
import 'rxjs';
import 'rxjs/operators';

let cachedCallable;
function buyTicket(ff, channelId, eventId, ticketId, paymentMethodId, videoId, selectedCurrency) {
  if (!cachedCallable) {
    cachedCallable = declareCallable("payments-buyticket", ff);
  }
  return cachedCallable({ channelId, eventId, videoId, ticketId, selectedCurrency, paymentMethodId });
}

export { buyTicket };

