import { isTicketValidForVideo, getEffectivePrice, willTicketExpireBy } from '../business-logic/tickets.js';

function getTickets(event, options) {
  const now = options?._nowRef || /* @__PURE__ */ new Date();
  if (!event.tickets) {
    return [];
  }
  return event.tickets.filter((t) => options?.video ? isTicketValidForVideo(t, options.video, options.video.docId) : true).filter((t) => options?.includeInvalid ? true : t.available && getEffectivePrice(t, now) !== void 0).filter((t) => options?.excludeExpired ? !willTicketExpireBy(event, t.scope, now, now) : true).filter((t) => options?.includeTicketsWithLimitedRedemptions ? true : !t.scope?.redemptionLimit);
}

export { getEffectivePrice, getTickets };

