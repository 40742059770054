import { takeUntil, map } from 'rxjs';
import { collectionRef, getDocData, getDocData$ } from '../base/collection.js';
import { execQuery, batchedInQuery } from '../base/query.js';
import { converter } from './ChannelDataConverter.js';

const CHANNELS_COLLECTION = "channels";
function colRef(fs) {
  return collectionRef(CHANNELS_COLLECTION, fs, converter());
}
function getChannel(fs, id) {
  return getDocData(id, colRef(fs));
}
function getChannel$(fs, id, until$) {
  return getDocData$(id, colRef(fs)).pipe(takeUntil(until$));
}
function getChannelByDomainAndSlug(fs, domain, slug) {
  return execQuery(
    colRef(fs),
    [
      ["slug", "==", slug],
      ["domain", "==", domain]
    ]
  ).pipe(
    map((result) => result.length === 1 ? result[0] : void 0)
  );
}
function getChannelsByDomain(fs, domain) {
  return execQuery(colRef(fs), ["domain", "==", domain]);
}
function getChannelsByIds(fs, ids) {
  return batchedInQuery(colRef(fs), ids);
}

export { colRef, getChannel, getChannel$, getChannelByDomainAndSlug, getChannelsByDomain, getChannelsByIds };

