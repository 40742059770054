import { combineLatest, first, map, Observable } from 'rxjs';
import {
  checkTermsConsent,
  checkUserConsent,
  TermsConsentCheck,
  updateUserConsent,
  UserConsentCheck,
} from '@yoimo/client-sdk/users';
import {
  getChannelPublicSettings,
  getUserConsentEntry,
  getUserConsentRequests,
} from '@yoimo/client-sdk/channels';

import {
  ChannelTerms,
  UpdateUserConsentRequest,
  UpdateUserConsentResponse,
  UserConsentRequest,
  UserConsentSettings,
} from '@yoimo/interfaces';

import { Firestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Functions } from '@angular/fire/functions';

export type GroupedConsentResponse = {
  grouped: Array<
    UserConsentSettings['groups'][number] & {
      items: UserConsentRequest[];
    }
  >;
  ungrouped: UserConsentRequest[];
};

@Injectable({ providedIn: 'root' })
export class ConsentService {
  constructor(private ff: Functions, private fs: Firestore) {}

  getChannelRequests$(
    channelId: string
  ): Observable<[UserConsentRequest[], ChannelTerms[]]> {
    return this._getConsentSettings$(channelId).pipe(
      map((res) => [res.requests, res.terms])
    );
  }

  getGroupedConsents$(channelId: string): Observable<GroupedConsentResponse> {
    return this._getConsentSettings$(channelId).pipe(
      map((settings) => ({
        ungrouped: settings.requests.filter((req) => req.group === null),
        grouped: settings.groups.map((group) => ({
          ...group,
          items: settings.requests.filter((req) => req.group === group.id),
        })),
      }))
    );
  }

  /** Get the response to T&C and general consent requests */
  getConsentEntry$(
    channelId: string,
    userId: string
  ): Observable<[UserConsentCheck, TermsConsentCheck] | null> {
    return combineLatest([
      this._getConsentSettings$(channelId),
      getChannelPublicSettings(this.fs, channelId),
      getUserConsentEntry(this.fs, channelId, userId),
    ]).pipe(
      map(([channelRequests, settings, userConsentEntry]) => {
        if (!channelRequests) return null;
        if (!userConsentEntry) return null;

        return [
          checkUserConsent(settings, userConsentEntry),
          checkTermsConsent(settings, userConsentEntry),
        ];
      })
    );
  }

  /**
   * @remarks Assumes that the user already has a consent entry
   */
  updateConsents$(
    payload: UpdateUserConsentRequest
  ): Observable<UpdateUserConsentResponse> {
    return updateUserConsent(this.ff, { ...payload, merge: true });
  }

  /**
   * If a user has not yet submitted a consent response, they should be disconnected,
   * forcing them to go through the consent process.
   *
   * @returns A boolean indicating that the channel has consent requests AND the user hasn't ever replied to it
   */
  isMissingConsentEntry$(
    channelId: string,
    userId: string
  ): Observable<boolean> {
    return combineLatest([
      this.getChannelRequests$(channelId),
      this.getConsentEntry$(channelId, userId),
    ]).pipe(
      map(([channelRequests, userEntry]) => {
        if (!channelRequests.flat().length) {
          // Channel hasn't defined any consent settings
          return false;
        }

        if (!userEntry) {
          // User hasn't replied yet
          return true;
        }

        return userEntry
          .flat()
          .some((entry) => entry.needUpdate.length || entry.required.length);
      })
    );
  }

  private _getConsentSettings$(
    channelId: string
  ): Observable<UserConsentSettings> {
    return getChannelPublicSettings(this.fs, channelId).pipe(
      map(getUserConsentRequests)
    );
  }
}
