import { Injectable, ViewChild } from '@angular/core';
import { TicketOptionsComponent } from '../../features/ticket-options/ticket-options.component';
import {
  Subject,
  combineLatest,
  map,
  take,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { BillingPeriodicity } from '@yoimo/interfaces';

import { EventWithDocId, getTickets } from '@yoimo/client-sdk/events';
import { VideoWithDocId } from '@yoimo/client-sdk/videos';
import { Option } from '../../features/ticket-options/ticket-options-types';
import { ResolvedOptionsListLink } from '@yoimo/client-sdk/pages';
import { ChannelWithDocId } from '@yoimo/client-sdk/channels';
import {
  AccessControlService,
  AuthService,
  logger,
  ScopeService,
  TicketsService,
} from '@core/services';

@Injectable({ providedIn: 'root' })
export class TicketOptionsService {
  @ViewChild(TicketOptionsComponent) ticketOptions?: TicketOptionsComponent;
  readonly onPaymentResult$ = new Subject<'success' | 'failure'>();

  readonly channel$: Observable<ChannelWithDocId>;

  constructor(
    private ticketsService: TicketsService,
    private authService: AuthService,
    private scopeService: ScopeService,
    private accessControlService: AccessControlService
  ) {
    this.channel$ = this.scopeService.listenToChannel();
  }

  private getModalInstance(): TicketOptionsComponent {
    if (!this.ticketOptions) {
      logger.logCriticalError(
        'Could not find TicketOptions element',
        'CONTENT-ACCESS'
      );
      throw new Error('Ticket options instance undefined');
    }
    return this.ticketOptions;
  }

  onDestroy(): void {
    this.onPaymentResult$.complete();
  }

  setModalReference(ref: TicketOptionsComponent) {
    this.ticketOptions = ref;
  }

  getOptionId(option: Option, periodicity?: BillingPeriodicity): string {
    return option.type === 'TICKET'
      ? this.getTicketOptionId(
          option.ticketData.id,
          option.eventId,
          option.videoId
        )
      : this.getPlanOptionId(option.planData.docId, periodicity);
  }

  getTicketOptionId(
    ticketId: string,
    eventId?: string,
    videoId?: string
  ): string {
    return ['ticket', eventId, ticketId, videoId].filter(Boolean).join(':');
  }

  getPlanOptionId(planId: string, periodicity?: BillingPeriodicity): string {
    return `plan:${planId}:${periodicity}`;
  }

  /**
   * Check if there is a pre-selected purchasing option
   */
  checkForPreSelectedTicket(): void {
    this.ticketsService
      .getPreselectedTicket$()
      .pipe(take(1))
      .subscribe(async (ticketId) => {
        // We only need to check if a ticketId is provided as the ticketService
        // will automatically redirect the user is not logged in.
        if (ticketId) this.openOption(ticketId);
        await this.ticketsService.clearTicketQueryParams();
      });
  }

  /**
   * Open a specific option for purchase
   *
   * @param optionId Can describe a ticket or a plan, as follows:
   * - Plan -> `plan:optionId:optionPeriodicity?`
   * - Ticket -> `ticket:eventId:ticketId?:videoId?`
   */
  openOption(optionId: string) {
    const ticketOptions = this.getModalInstance();
    ticketOptions.reset();
    const [type, id1, id2, id3] = optionId.split(':');
    switch (type) {
      case 'plan':
        // id1 - planId
        // id2 - periodicity
        ticketOptions.openPlan(id1, id2 as BillingPeriodicity);
        break;
      case 'ticket':
        // id1 - eventId
        // id2 - ticketId
        // id3 - videoId
        if (!id1) {
          throw new Error('Event is undefined');
        }
        ticketOptions.openTicket(id2, id1, id3);
        break;
    }
  }

  /**
   * Open provided list of options for purchase
   * @param sections Sections with id's of options to be rendered
   * @param title Title to be used on the dialog for these options
   * TODO: handle Plan unavailable states
   * @param isSubscriptionPaused Information if user does not have access because of paused subscription.
   */
  openOptionsList(
    sections: ResolvedOptionsListLink['sections'],
    title?: string,
    isSubscriptionPaused?: boolean
  ) {
    this.getModalInstance().reset();
    this.getModalInstance().openOptionsList(
      sections,
      title,
      isSubscriptionPaused
    );
  }

  openAllPurchaseOptions(
    event?: EventWithDocId,
    video?: VideoWithDocId,
    isSubscriptionPaused?: boolean
  ): void {
    this.channel$
      .pipe(
        switchMap((channel) => {
          const plans$ = this.accessControlService.getApplicablePlans$(
            channel.docId,
            event,
            video
          );
          const tickets = event
            ? getTickets(event, {
                includeInvalid: false,
                excludeExpired: true,
                includeTicketsWithLimitedRedemptions: true,
                video,
              })
            : [];

          return combineLatest([plans$, of(tickets)]);
        }),
        map(([plans, tickets]) => {
          const plansList = plans.map((p) => {
            return { plan: p };
          });
          const ticketList = tickets.map((t) => {
            if (!event) {
              throw new Error('Event undefined');
            }
            return { ticket: t, event: event, video };
          });
          return [
            {
              title: '',
              products: [...plansList, ...ticketList],
            },
          ];
        }),
        take(1)
      )
      .subscribe((products) => {
        this.openOptionsList(products, '', isSubscriptionPaused);
      });
  }
}
