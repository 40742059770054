/* istanbul ignore file */

import { PagedQueryCommand, PagedQueryResult } from '@yoimo/client-sdk/base';
import { filter, map, Observable, OperatorFunction, pipe, Subject } from 'rxjs';

/**
 * @Remark for `stream$` that returns a buffered result of size (pageSize * number of times loaded).
 * This function filters items based on the predicate provided.
 * After the 'paged' result is filtered, the filtered result may not always match the requested page limit/size.
 * To solve this internally trigger the pager (filterPager$) to fetch the next set of items until filtered results meets the size requested.
 *
 * @Note Should be provided by the client-sdk when using paged queries as it already keeps track of the different pages
 * and has direct access to the data buffer.
 */
export function filterPagedResults<T>(
  predicate: (item: T) => boolean,
  size: number,
  pager$: Observable<PagedQueryCommand>,
  filterPager$: Subject<PagedQueryCommand>
): OperatorFunction<PagedQueryResult<T>, PagedQueryResult<T>> {
  let pagerCount = 0;
  pager$.subscribe((state) => {
    state === 'init' ? (pagerCount = 1) : pagerCount++;
  });

  return pipe(
    map((result) => {
      const filteredResult = result.data.filter((item) => predicate(item));
      if (
        filteredResult.length < pagerCount * size &&
        result.paging.canLoadNext
      ) {
        filterPager$.next('next');
        return null;
      }
      return {
        ...result,
        data: filteredResult,
      };
    }),
    filter((t): t is PagedQueryResult<T> => t !== null)
  );
}
