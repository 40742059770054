/* istanbul ignore file */

import { Branding, EventListSource, Ticket } from '@yoimo/interfaces';
import {
  ChannelWithDocId,
  getActivePage as getChannelPage,
} from '@yoimo/client-sdk/channels';
import {
  EventSourceQueryOption,
  EventWithDocId,
  getEventsFromSource,
  getTickets,
} from '@yoimo/client-sdk/events';
import {
  combineLatest,
  map,
  Observable,
  of,
  Subject,
  take,
  takeUntil,
} from 'rxjs';

import { Firestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { getActivePage as getEventPage } from '@yoimo/client-sdk/events';
import { AccessControlService } from './access-control.service';
import { PurchaseOption } from '@core/interfaces/types';

@Injectable({ providedIn: 'root' })
export class EventsService {
  constructor(
    private fs: Firestore,
    private accessControlService: AccessControlService
  ) {}

  getEvents$(
    source: EventListSource,
    options: EventSourceQueryOption,
    until$?: Subject<void>
  ): Observable<EventWithDocId[]> {
    const events$ = getEventsFromSource(this.fs, source, options);

    if (until$) events$.pipe(takeUntil(until$));

    return events$;
  }

  getEventBranding(
    channel: ChannelWithDocId,
    event: EventWithDocId
  ): Partial<Branding> {
    return {
      ...channel?.branding,
      ...getChannelPage(channel)?.brandingOverride,
      ...getEventPage(event)?.brandingOverride,
    };
  }

  getApplicableProducts$(
    channelId: string,
    event: EventWithDocId
  ): Observable<PurchaseOption[]> {
    return combineLatest([
      this.accessControlService.getApplicablePlans$(channelId, event),
      of(
        getTickets(event, {
          excludeExpired: true,
          includeTicketsWithLimitedRedemptions: false,
        })
      ),
    ]).pipe(
      take(1),
      map(([plans, tickets]) => {
        const options: PurchaseOption[] = plans.map((p) => {
          return { plan: p };
        });
        options.push(
          ...tickets.map((t) => {
            return { ticket: t, event: event };
          })
        );
        return options;
      })
    );
  }
}
