import {
  canUnpauseSubscription,
  getProviderOfSubscription,
  getRecurringPriceParameters,
  isSubscriptionCancellable,
  isSubscriptionUncancelable,
} from '@yoimo/client-sdk/business-logic';
import { ChannelWithDocId } from '@yoimo/client-sdk/channels';
import { BillingPeriodConfiguration, Subscription } from '@yoimo/interfaces';
import {
  PlanWithDocId,
  SubscriptionWithDocId,
} from '@yoimo/client-sdk/subscriptions';
import { PricePipe, SubscriptionTicketCardState } from '@yoimo/joymo-ui';

import { planToPlanOption } from '../../features/ticket-options/data-transformers';
import { SubscriptionCardDetails } from '../../features/ticket-options/ticket-options-types';
import { getDateString } from 'shared-lib/core';
import { ParsePeriodicityPipe } from '@core/pipes';

const getBillingPeriodConf = (
  config: Subscription['selectedConfig'],
  plan: PlanWithDocId
): BillingPeriodConfiguration => {
  const billingPeriodConf = plan.priceAlternatives
    .find(({ currency }) => currency == config.currency)
    ?.billingPeriods.find(({ period }) => period === config.periodicity);

  if (!billingPeriodConf) throw new Error('BillingConfigNotFound');

  return billingPeriodConf;
};

const getManageLabel = (subscription: SubscriptionWithDocId) => {
  if (['PAUSED', 'CANCELLING', 'TERMINATED'].includes(subscription.status))
    return;
  if (getProviderOfSubscription(subscription) !== 'STRIPE') return;
  return $localize`:@@subscriptionCardManageButtonLabel:Manage subscription`;
};

const getAccessUntilLabel = (
  subscription: SubscriptionWithDocId,
  locale: string
) => {
  if (!subscription.cancellation) return;
  return $localize`:@@subscriptionCardAccessUntil:You have access until ${getDateString(
    subscription.cancellation.effective,
    locale
  )}:date:`;
};

const getCardState = (
  subscription: SubscriptionWithDocId,
  locale: string
): {
  state: SubscriptionTicketCardState;
  stateMessage: string | undefined;
} => {
  const state = <SubscriptionTicketCardState>{
    ACTIVE: 'ACTIVE',
    CANCELLING: 'CANCELLED',
    NEEDS_ACTION: 'INACTIVE',
    PAUSED: 'PAUSED',
    PENDING: 'PURCHASING',
    TERMINATED: 'CANCELLED',
    TRIALING: 'ACTIVE',
  }[subscription.status];
  let stateMessage: string | undefined;

  if (state === 'CANCELLED') {
    stateMessage = $localize`:@@subscriptionCardIsCancelled:Cancelled subscription`;
  }
  if (state === 'INACTIVE') {
    stateMessage = $localize`:@@subscriptionCardIsInactiveMessage:Inactive, please update payment details`;
  }
  if (state === 'PAUSED') {
    stateMessage = subscription.pausedUntil
      ? $localize`:@@subscriptionCardIsPausedUntil:Paused until ${getDateString(
          subscription.pausedUntil.date,
          locale
        )}`
      : $localize`:@@subscriptionCardIsPaused:Paused subscription`;
  }

  return { state, stateMessage };
};

const getReactivateLabel = (
  subscription: Subscription,
  plan: PlanWithDocId
): string | undefined => {
  return (subscription.status === 'CANCELLING' &&
    isSubscriptionUncancelable(subscription)) ||
    (subscription.status === 'PAUSED' &&
      canUnpauseSubscription(subscription, plan))
    ? $localize`:@@reactivateSubscriptionLabel:Reactivate subscription`
    : undefined;
};

const getInstallmentsLabel = (
  locale: string,
  subscription: Subscription,
  plan: PlanWithDocId
): string | undefined => {
  const { selectedConfig } = subscription;
  const billingPeriodConf = getBillingPeriodConf(
    subscription.selectedConfig,
    plan
  );

  if (!selectedConfig.installments || !billingPeriodConf.installments) {
    return undefined;
  }

  return getInstallmentsDescription(
    locale,
    billingPeriodConf,
    selectedConfig.currency
  );
};

const getBillingDetailLabel = (
  locale: string,
  subscription: Subscription,
  plan: PlanWithDocId
): string | undefined => {
  if (subscription.selectedConfig.installments) {
    return undefined;
  }
  const billingConf = getBillingPeriodConf(subscription.selectedConfig, plan);

  const price = new PricePipe(locale).transform(
    billingConf.basePrice,
    subscription.selectedConfig.currency
  );
  const periodicity = new ParsePeriodicityPipe().transform(
    subscription.selectedConfig.periodicity
  );

  return $localize`:@@subscriptionCardBillingDetails:${price} ${periodicity.toLowerCase()}`;
};

/** Generate the card details for a given plan+subscription combination */
export function parseSubscriptionCardDetails(
  channel: ChannelWithDocId,
  plan: PlanWithDocId,
  subscription: SubscriptionWithDocId,
  locale: string
): SubscriptionCardDetails {
  const option = planToPlanOption(channel, plan, locale, false);
  const cardState = getCardState(subscription, locale);
  return {
    ...option.cardDetails,
    ...cardState,
    accessUntilLabel: getAccessUntilLabel(subscription, locale),
    billingDetailsLabel: getBillingDetailLabel(locale, subscription, plan),
    cancelDetailsLabel:
      subscription.status === 'CANCELLING'
        ? $localize`:@@subscriptionCardIsCancellingLabel:Subscription is scheduled for cancelling.`
        : undefined,
    cancelLabel: isSubscriptionCancellable(subscription)
      ? $localize`:@@subscriptionCardCancelLabel:Cancel subscription`
      : undefined,
    manageLabel: getManageLabel(subscription),
    reactivateLabel: getReactivateLabel(subscription, plan),
    installmentsDetailsLabel: getInstallmentsLabel(locale, subscription, plan),
  };
}

export function getInstallmentsDescription(
  localeId: string,
  billingConfig: BillingPeriodConfiguration,
  currency: string
): string {
  const params = getRecurringPriceParameters(billingConfig, true);
  params.totalPerPeriod = new PricePipe(localeId).transform(
    params.totalPerPeriod,
    currency
  );

  if (params.paymentPeriodicity === 'MONTHLY') {
    return $localize`:@@payInInstallmentsTermsPerMonth:${params.totalPerPeriod}:price:/mo. for ${params.bindingPeriods}:installmentsCount: mo.`;
  }

  throw new Error('PlanPeriodicityDoesNotSupportInstallments');
}
