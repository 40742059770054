import '@angular/fire/functions';
import 'rxjs';
import 'rxjs/operators';
import { collectionRef } from '../base/collection.js';
import { pagedQuery } from '../base/query.js';
import { converter } from './SoldTicketDataConverter.js';

function colRef(fs, channelId) {
  return collectionRef(`channels/${channelId}/soldTickets`, fs, converter());
}
const DEFAULT_PAGE_LIMIT = 10;
function getSoldTickets(fs, userId, options) {
  const wheres = [
    ["userId", "==", userId]
  ];
  if (options.eventId) {
    wheres.push(["eventId", "==", options.eventId]);
  }
  if (options.status) {
    wheres.push(["status", "==", options.status]);
  }
  return pagedQuery(
    colRef(fs, options.scopeId),
    wheres,
    options.pager,
    {
      qLimit: options.limit || DEFAULT_PAGE_LIMIT
    }
  );
}

export { getSoldTickets };

