import '@angular/fire/functions';
import 'rxjs';
import 'rxjs/operators';
import { collectionRef, getDocData } from '../base/collection.js';
import '@angular/fire/firestore';
import { getActivePage as getActivePage$1 } from '../pages/utils.js';
import '@yoimo/interfaces/validators';
import { converter } from './ChannelPublicSettingsDataConverter.js';
import { converter as converter$1 } from './UserConsentEntryDataConverter.js';

function getActivePage(channel) {
  return getActivePage$1(channel.pages);
}
function getChannelPublicSettings(fs, channelId) {
  const path = `channels/${channelId}/settings`;
  const publicSettingsCol = collectionRef(path, fs, converter());
  return getDocData("public", publicSettingsCol);
}
function getExternalPlanProviders(settings, userId) {
  return Object.entries(settings.externalPlanProviders || {}).map(([id, p]) => ({ id, ...p })).map(({ enabled, ...p }) => ({
    enabled: typeof enabled === "boolean" ? enabled : enabled.includes(userId),
    ...p
  }));
}
function getUserConsentRequests(settings) {
  return settings.userConsent || { groups: [], requests: {} };
}
function getUserConsentEntry(fs, channelId, userId) {
  const path = `channels/${channelId}/userConsents`;
  const userConsentDoc = collectionRef(path, fs, converter$1());
  return getDocData(userId, userConsentDoc);
}
function getVideoAttributesMapping(settings) {
  return settings.attributesMapping || null;
}
function getChannelNavigation(channel) {
  return channel.channelNavigation ?? [
    { type: "CALENDAR" },
    { type: "EVENTS", listSources: [
      { label: "Past and Ongoing", source: { onlyState: "PASSED_AND_ACTIVE" }, order: "DESC" },
      { label: "Upcoming", source: { onlyState: "UPCOMING" }, order: "ASC" }
    ] }
  ];
}

export { getActivePage, getChannelNavigation, getChannelPublicSettings, getExternalPlanProviders, getUserConsentEntry, getUserConsentRequests, getVideoAttributesMapping };

