import '@angular/fire/functions';
import 'rxjs';
import 'rxjs/operators';
import '@angular/fire/firestore';
import { cacheConverter, DefaultDataConverter } from '../base/DefaultDataConverter.js';
import { Plan } from '@yoimo/interfaces/validators';

class PlanDataConverter extends DefaultDataConverter {
  constructor() {
    super(Plan);
  }
}
const converter = cacheConverter(PlanDataConverter);

export { PlanDataConverter, converter };

