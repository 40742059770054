import { isCollectionReference } from './collection.js';
import { query, where, limit, orderBy, getDocs, documentId, startAfter, endBefore } from '@angular/fire/firestore';
import { from, takeWhile, mergeScan, map, combineLatest, of } from 'rxjs';
import { isDefined } from '../utils.js';

function buildQuery(collectionRef, qWhere, options) {
  let q = query(collectionRef);
  if (qWhere !== void 0) {
    const thereIsOnlyOneWhere = qWhere.length === 3 && typeof qWhere[1] === "string";
    const whereClauses = thereIsOnlyOneWhere ? [qWhere] : qWhere;
    const wheres = whereClauses.map(
      ([f, op, val]) => {
        if (val === void 0) {
          throw new Error(
            `undefined was passed as a value for a where clause: in ${String(f)} ${op} ${val}`
          );
        }
        return where(f, op, val);
      }
    );
    q = query(q, ...wheres);
  }
  if (options?.qLimit !== void 0) {
    q = query(q, limit(options?.qLimit));
  }
  if (options?.qOrderBy !== void 0) {
    q = query(q, ...options.qOrderBy.map((ob) => orderBy(ob.field, ob.sort)));
  }
  return q;
}
function execQuery(queryOrColRef, qWhere, options) {
  const q = isCollectionReference(queryOrColRef) && qWhere ? buildQuery(queryOrColRef, qWhere, options) : queryOrColRef;
  return from(getDocs(q).then((querySnapshot) => querySnapshot.docs.map((d) => d.data())));
}
function _pageQueryAccumulator(state, command) {
  if (!state.started && command !== "init") {
    throw new Error(
      'Invalid paging direction requested. The query should be initialized by sending the "init" command first'
    );
  }
  if (state.started && (command === "next" && !state.nextRef || command === "previous" && !state.prevRef)) {
    return of(state);
  }
  if (!state.started || command === "init") {
    return from(getDocs(query(state.q))).pipe(
      map(_queryDocsToPagedQueryState(command, state))
    );
  }
  if (typeof command === "object" && command.type === "prune") {
    return of({
      ...state,
      buffer: state.buffer.filter((s) => !command.ids.includes(s.docId))
    });
  }
  const newQ = query(
    state.q,
    command === "next" ? startAfter(state.nextRef) : endBefore(state.prevRef)
  );
  return from(getDocs(newQ)).pipe(
    map(_queryDocsToPagedQueryState(command, state))
  );
}
function _pagedQueryReset(oldState) {
  const { expectedPageSize, q } = oldState;
  return function(newData) {
    const { docs, size } = newData;
    if (size == 0) {
      return { q: oldState.q, expectedPageSize: oldState.expectedPageSize, started: true, nextRef: void 0, prevRef: void 0, buffer: [] };
    } else {
      return {
        started: true,
        q,
        expectedPageSize,
        nextRef: size < expectedPageSize ? void 0 : docs[docs.length - 1],
        prevRef: size < expectedPageSize ? void 0 : docs[0],
        buffer: docs.map((d) => d.data())
      };
    }
  };
}
function _pagedQueryNext(oldState) {
  const { expectedPageSize, q } = oldState;
  return function(newData) {
    const { docs, size } = newData;
    return {
      started: true,
      q,
      expectedPageSize,
      nextRef: size < expectedPageSize ? void 0 : docs[docs.length - 1],
      prevRef: oldState.started ? oldState.prevRef : void 0,
      buffer: [...oldState.started ? oldState.buffer : [], ...docs.map((d) => ({ ...d.data(), docId: d.id }))]
    };
  };
}
function _pagedQueryPrevious(oldState) {
  const { expectedPageSize, q } = oldState;
  return function(newData) {
    const { docs, size } = newData;
    return {
      started: true,
      q,
      expectedPageSize,
      nextRef: oldState.started ? oldState.prevRef : void 0,
      prevRef: size < expectedPageSize ? void 0 : docs[0],
      buffer: [...docs.map((d) => ({ ...d.data(), docId: d.id })), ...oldState.started ? oldState.buffer : []]
    };
  };
}
function _queryDocsToPagedQueryState(direction, oldState) {
  switch (direction) {
    case "init":
      return _pagedQueryReset(oldState);
    case "next":
      return _pagedQueryNext(oldState);
    case "previous":
      return _pagedQueryPrevious(oldState);
    default:
      throw new Error(`Unexpected command to process ${direction}`);
  }
}
function pagedQuery(collectionRef, qWhere, loadNext$, options) {
  const limit2 = options?.qLimit || 10;
  const initialState = {
    started: false,
    q: buildQuery(collectionRef, qWhere, { ...options, qLimit: limit2 }),
    expectedPageSize: limit2
  };
  return loadNext$.pipe(
    takeWhile((c) => c !== "complete"),
    mergeScan(
      _pageQueryAccumulator,
      initialState,
      1
    ),
    map((state) => {
      return {
        data: state.buffer,
        paging: {
          canLoadNext: !!state.nextRef,
          canLoadPrevious: !!state.prevRef
        }
      };
    })
  );
}
function batchedInQuery(collectionRef, ids) {
  const valuesToIndexMap = /* @__PURE__ */ new Map();
  const batches = ids.reduce((acc, e, i) => {
    valuesToIndexMap.set(e, i);
    if (!(i % 10)) {
      acc.push([]);
    }
    acc[acc.length - 1].push(e);
    return acc;
  }, []);
  return combineLatest(
    batches.map((b) => execQuery(collectionRef, [documentId(), "in", b]))
  ).pipe(
    map((results) => {
      const sortedResults = new Array(ids.length);
      results.forEach(
        (batchOfDocs) => {
          batchOfDocs.forEach((d) => {
            const idx = valuesToIndexMap.get(d.docId);
            if (idx !== void 0) {
              sortedResults[idx] = d;
            }
          });
        }
      );
      return sortedResults;
    })
  );
}
function batchedInQueryFiltered(collectionRef, ids) {
  return batchedInQuery(collectionRef, ids).pipe(
    map((r) => r.filter(isDefined))
  );
}

export { batchedInQuery, batchedInQueryFiltered, buildQuery, execQuery, pagedQuery };

