import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Alignment, PopoverOptions, Position } from '@yoimo/joymo-ui';
import {
  Observable,
  ReplaySubject,
  Subject,
  bufferTime,
  filter,
  from,
  map,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs';
import { PlatformService } from './platform.service';
import { Popover, featureTexts } from '@core/utilities';
import {
  filterOnboardings,
  getAuthenticatedUserInfo$,
  saveOnboardingStatus,
} from '@yoimo/client-sdk/users';
import { Auth } from '@angular/fire/auth';
import { Firestore } from '@angular/fire/firestore';
import { getStableBucket } from '@yoimo/client-sdk/business-logic';
import { Feature } from 'shared-lib/core';

@Injectable({ providedIn: 'root' })
export class UserOnboardingService implements OnDestroy {
  readonly featureIds: Feature[] = ['PROFILE', 'ATTRIBUTES', 'PAGES'];
  private destroy$ = new Subject<void>();
  protected _features$ = new ReplaySubject<
    PopoverOptions & { feature: Feature }
  >(1);
  private featuresToShow: string[] = [];

  // Handles user navigating away from an ongoing onboarding process.
  private _reset$ = new Subject<boolean>();
  reset$ = this._reset$.asObservable();

  constructor(
    private router: Router,
    private platformService: PlatformService,
    private auth: Auth,
    private fs: Firestore
  ) {
    if (this.platformService.isServer()) {
      return;
    }
  }

  getSteps$(userId: string): Observable<Popover[]> {
    return this.router.events.pipe(
      tap((event) => {
        if (event instanceof NavigationStart && this._features$) {
          this._reset$.next(true);
          this._features$.complete();
          // Reset to new stream of features
          this._features$ = new ReplaySubject(1);
        }
      }),
      filter((event) => event instanceof NavigationEnd),
      switchMap((_) => this.fetchUserFeatureIds$(userId)),
      takeWhile((ids) => !!ids.length),
      // stop here if no ids to highlight
      tap((ids) => (this.featuresToShow = ids)),
      switchMap((_) => this._features$.pipe(bufferTime(1000))),
      filter((res) => !!res.length),
      map((features) => {
        return features
          .filter((val) => this.featuresToShow.includes(val.feature))
          .map((val) => ({
            ...val,
            title: featureTexts[val.feature].title,
            description: featureTexts[val.feature].description,
          }));
      }),
      takeUntil(this.destroy$)
    );
  }

  // get element reference of all features to highlight on page.
  addHostForFeature(
    feature: Feature,
    host: ElementRef,
    position: Position,
    alignment: Alignment
  ): void {
    this._features$.next({
      feature,
      host,
      position,
      alignment,
      highlightHost: true,
      isHostFixed: feature === 'PROFILE' || feature === 'PAGES',
    });
  }

  fetchUserFeatureIds$(userId: string): Observable<string[]> {
    return this.shouldShowTutorial(userId).pipe(
      filter((res): res is true => !!res),
      switchMap((_) => getAuthenticatedUserInfo$(this.auth, this.fs)),
      switchMap((userInfo) => {
        return filterOnboardings(this.featureIds, userInfo);
      }),
      take(1)
    );
  }

  shouldShowTutorial(userId: string): Observable<boolean> {
    /** Show tutorial to only 20% users */
    return from(getStableBucket(10, `ONBOARDING_TUTORIAL:${userId}`)).pipe(
      map((res) => res < 2)
    );
  }

  saveUserStatus$(features: string[]): Observable<void> {
    return getAuthenticatedUserInfo$(this.auth, this.fs).pipe(
      take(1),
      switchMap((userInfo) => {
        return saveOnboardingStatus(
          this.fs,
          userInfo.docId,
          userInfo,
          features
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
