export const PARSER_IMPLEMENTATION_TOKEN = 'ParserImplementation';

export const PROVIDED = {
  scopeService: 'SCOPE_SERVICE',
  eventService: 'EVENTS_SERVICE',
  ticketOptionsService: 'TICKET_OPTIONS_SERVICE',
  accessControlService: 'ACCESS_CONTROL_SERVICE',
  userOnboardingService: 'USER_ONBOARDING_SERVICE',
  localeMapping: 'LOCALE_MAPPING',
};

export type LocaleLabel =
  | 'filtersFloatingButtonLabel'
  | 'dateRangePlaceholder'
  | 'dateRangeSubmitLabel'
  | 'dateRangeClearLabel'
  | 'attributeApplyLabel'
  | 'attributeResetFilterLabel'
  | 'attributesLiveAndUpcomingLabel'
  | 'attributesShowAllStreamsLabel'
  | 'attributesFilterByDateLabel'
  | 'calendarPageNoStreamsFoundHeading'
  | 'calendarPageNoStreamsFoundDescription'
  | 'videoFiltersPastAndOngoingEventLabel'
  | 'videoFiltersUpcomingEventLabel'
  | 'videoFiltersSelectedLabel'
  | 'liveLabel'
  | 'videoLockText'
  | 'noActivePageMessage'
  | 'loadMoreButtonLabel'
  | 'socialsShareMessage';

export type LocaleMapping = Record<LocaleLabel, string>;
