// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from './environment.tpl';

const DEFAULT_DOMAIN = 'joymo-arena-20-staging.web.app';

export const environment: Environment = {
  joymo: {
    authUrl: 'https://auth.staging.joymo.no',
    defaultSupportEmail: 'support@joymo.tv',
  },
  firebase: {
    projectId: 'yoimo-live-staging',
    appId: '1:662686805404:web:58a043f9709e55d52aec59',
    databaseURL: 'https://yoimo-live-staging.firebaseio.com',
    storageBucket: 'yoimo-live-staging.appspot.com',
    locationId: 'us-central1',
    apiKey: 'AIzaSyAJyUKKe9MzNJpHEHRU7P-PU2E9_1ye10w',
    authDomain: 'yoimo-live-staging.firebaseapp.com',
    messagingSenderId: '662686805404',
    measurementId: 'G-6842M9XJ2N',
  },
  production: false,
  emulator: false,
  behavior: {
    defaultIcon: 'joymo',
    betaDomains: [],
    cacheMaxAge: 60,
    defaultDomain: DEFAULT_DOMAIN,
    emulateDomain: null,
    redirectToCanonicalUrl: false,
    defaultTheme: {
      accentColor: '#00A83C',
      backgroundColor: '#ffffff',
      themeStyle: 'rounded',
    },
    domainAliases: {
      [DEFAULT_DOMAIN]: null,
      localhost: null,
    },
    testFeatures: {
      channelsWithVideoFeedback: [
        'M0W9wq1kSRxAZ4gbCeBB',
        'rNfPLHYvauOdZKR4v0SH',
        'ypEJz8C8jWu1IMzGgSmD',
        'dGCjlW5252mTINqv8NzS',
        'DRbVOTtEVRKeBeXfdTzS',
        // dev test
        'iWEW94gTQO5WegCgdUuM',
        'C6g2RqibUjDyOael69XQ',
      ],
      favicons: {
        default: 'assets/favicons/joymo.png',
      },
    },
  },
  sentry: {
    browser:
      'https://0f41f2c4c0a6401683a88bac3749e554@o1234650.ingest.sentry.io/6384077',
  },
  google: {
    analytics: {
      gtmId: 'GTM-W8BZDBW',
    },
  },
  enableRouterTracing: false,
  videoAnalytics: {
    accountCode: 'joymodev',
    enabled: true,
    debug: true,
    options: {
      'content.customDimension.1': 'STG',
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
