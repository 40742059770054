import { getDocData, collectionRef } from '../base/collection.js';
import { map } from 'rxjs';
import { execQuery, batchedInQueryFiltered, buildQuery, pagedQuery } from '../base/query.js';
import { converter } from './NamedTagDataConverter.js';

function TAGS_COLLECTION(channelId) {
  return `channels/${channelId}/tags`;
}
function colRef(fs, channelId) {
  return collectionRef(TAGS_COLLECTION(channelId), fs, converter());
}
function getNamedTag(fs, channelId, tag) {
  return execQuery(colRef(fs, channelId), ["name", "==", tag]).pipe(
    map((result) => result.length ? result[0] : void 0)
  );
}
function getNamedTagById(fs, channelId, id) {
  return getDocData(id, colRef(fs, channelId));
}
function getNamedTagByIds(fs, channelId, ids) {
  return batchedInQueryFiltered(colRef(fs, channelId), ids);
}
function getNamedTagFromSourceList(fs, source, options) {
  if ("tagIds" in source) {
    return getNamedTagByIds(fs, options.scopeId, source.tagIds);
  }
  const col = colRef(fs, options.scopeId);
  const query = buildQuery(col, ["type", "==", source.type], { qLimit: options.limit });
  return execQuery(query);
}
function getNamedTagFromSourceListPaged(fs, source, options) {
  if ("tagIds" in source) {
    throw new TypeError("tagIds is not supported in paged queries");
  }
  const col = colRef(fs, options.scopeId);
  return pagedQuery(col, ["type", "==", source.type], options.pager, { qLimit: options.limit });
}

export { getNamedTag, getNamedTagById, getNamedTagByIds, getNamedTagFromSourceList, getNamedTagFromSourceListPaged };

