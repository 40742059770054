async function hashString(input) {
  const text = new TextEncoder().encode(input);
  return new Uint8Array(await crypto.subtle.digest("SHA-1", text));
}
function attributeApplies(attributeDefinition, attributes) {
  const [attr, value] = attributeDefinition.split(":");
  let testVal;
  switch (attr) {
    case "attr0":
      if (attributes.attr0)
        testVal = attributes.attr0;
      else
        return false;
      break;
    case "attr1":
      if (attributes.attr1)
        testVal = attributes.attr1;
      else
        return false;
      break;
    case "attr2":
      if (attributes.attr2)
        testVal = attributes.attr2;
      else
        return false;
      break;
    case "attr3":
      if (attributes.attr3)
        testVal = attributes.attr3;
      else
        return false;
      break;
    default:
      return false;
  }
  return value === testVal;
}
function checkAttributeCoverage(check, attributes) {
  return check.kind === "ALL" ? check.list.every((a) => attributeApplies(a, attributes)) : check.list.some((a) => attributeApplies(a, attributes));
}

export { attributeApplies, checkAttributeCoverage, hashString };

