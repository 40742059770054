import '@angular/fire/functions';
import { map, from, switchMap, of } from 'rxjs';
import 'rxjs/operators';
import { collectionRef } from '../base/collection.js';
import { execQuery } from '../base/query.js';
import { converter } from './FeedbackDataConverter.js';
import { getUserTestingGroup } from '../business-logic/testing.js';

function colRef(fs) {
  return collectionRef(`feedbacks`, fs, converter());
}
function getFeedback(fs, channelId, userId, options) {
  const wheres = [
    ["channelId", "==", channelId],
    ["userId", "==", userId],
    ["type", "==", "VIDEO"],
    ["videoId", "==", options.videoId]
  ];
  return execQuery(colRef(fs), wheres, { qLimit: 1 }).pipe(map((data) => data[0]));
}
const TESTING_GROUPS = [10, 30];
const EXPERIMENT_ID = "WITH_WAIT_TIME";
const TEST_FACTOR = 1;
const MINIMUM_TIME_BETWEEN_FEEDBACKS = 24 * 60 * 60 * 1e3;
function shouldAskFeedback(fs, userInfo, channelId, videoId) {
  return from(getUserTestingGroup(userInfo, TEST_FACTOR, TESTING_GROUPS.length, EXPERIMENT_ID)).pipe(
    switchMap((userTestingGroup) => {
      if (userTestingGroup === null)
        return of({ show: false });
      return getFeedback(fs, channelId, userInfo.docId, {
        type: "VIDEO",
        videoId
      }).pipe(
        map((feedback) => {
          if (feedback) {
            return { show: false };
          }
          const WAIT_TIME = TESTING_GROUPS[userTestingGroup];
          const subscriptions = userInfo.subscriptions;
          const lastFeedback = subscriptions && subscriptions[channelId] && subscriptions[channelId].lastFeedbackProvided;
          if (!lastFeedback)
            return {
              show: true,
              waitTime: WAIT_TIME,
              testingGroup: `${EXPERIMENT_ID}:${userTestingGroup}`
            };
          const diff = Date.now() - lastFeedback.getTime();
          return {
            show: diff > MINIMUM_TIME_BETWEEN_FEEDBACKS,
            waitTime: WAIT_TIME,
            testingGroup: `${EXPERIMENT_ID}:${userTestingGroup}`
          };
        })
      );
    })
  );
}

export { getFeedback, shouldAskFeedback };

