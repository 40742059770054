import { declareCallable } from '../base/callable.js';
import '@angular/fire/firestore';
import 'rxjs';
import 'rxjs/operators';

let updateUserConsentCallable;
function updateUserConsent(ff, responses) {
  if (!updateUserConsentCallable) {
    updateUserConsentCallable = declareCallable("users-updateconsent", ff);
  }
  return updateUserConsentCallable(responses);
}

export { updateUserConsent };

