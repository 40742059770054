import { WindowService } from '@yoimo/joymo-ui';

/**
 * Pushes an event to the data layer
 *
 * @returns A snapshot of the layer at that moment in time
 */
export function pushToDataLayer<Key extends DataLayerEvent>(
  event: Key,
  eventParams: KnownEvents[Key]
): DataLayerItem[] {
  const dataLayer = getDataLayer();

  dataLayer.push({ event, eventParams });

  return dataLayer;
}

/** Data layer can be initialized and populated even before the script loads */
function getDataLayer(): DataLayerItem[] {
  if (!WindowService.isWindowAvailable) return [];
  const window = <WindowWithDataLayer>globalThis.window;

  window.dataLayer = window.dataLayer || [];
  return window.dataLayer;
}

/** Maps event names to their expected parameters  */
interface KnownEvents {
  'gtm.js': {
    start: number;
  };
  authentication: {
    provider: string | undefined;
  };
  resource_access: {
    'resource.access_level': string;
    'resource.id': string;
    'resource.name': string;
    'resource.type': string;
  };
}
type DataLayerEvent = keyof KnownEvents;
type DataLayerItem = {
  event: DataLayerEvent;
  eventParams: KnownEvents[DataLayerEvent];
};
type WindowWithDataLayer = (Window & typeof globalThis) & {
  dataLayer?: DataLayerItem[];
};
