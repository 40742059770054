import { getChannelsByDomain, getChannelByDomainAndSlug } from './crud.js';
import { take, map } from 'rxjs';

function getScope(fs, domain, slug) {
  const wrapChannelScope = (c) => ({
    scope: "CHANNEL",
    scopeId: c.docId,
    channel: c
  });
  if (domain !== null) {
    return getChannelsByDomain(fs, domain).pipe(
      take(1),
      map((channels) => {
        if (channels.length === 1) {
          return wrapChannelScope(channels[0]);
        }
        const found = channels.find((c) => c.slug === slug);
        return found ? wrapChannelScope(found) : void 0;
      })
    );
  } else {
    return getChannelByDomainAndSlug(fs, null, slug).pipe(
      map((channel) => channel ? wrapChannelScope(channel) : void 0)
    );
  }
}

export { getScope };

