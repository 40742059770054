import '@angular/fire/functions';
import 'rxjs';
import 'rxjs/operators';
import '@angular/fire/firestore';
import { cacheConverter, DefaultDataConverter } from '../base/DefaultDataConverter.js';
import { Subscription } from '@yoimo/interfaces/validators';

class SubscriptionDataConverter extends DefaultDataConverter {
  constructor() {
    super(Subscription);
  }
}
const converter = cacheConverter(SubscriptionDataConverter);

export { SubscriptionDataConverter, converter };

