import { allTagsApply, someTagsApply } from './tags.js';
import { checkAttributeCoverage } from './util.js';

function getEffectivePrice(ticket, allowInvalidReasonOrNowRef, event, _nowRef) {
  const {
    actualNowRef,
    actualAllowInvalidReason
  } = typeof allowInvalidReasonOrNowRef === "boolean" ? { actualNowRef: _nowRef, actualAllowInvalidReason: allowInvalidReasonOrNowRef } : { actualNowRef: allowInvalidReasonOrNowRef, actualAllowInvalidReason: false };
  if (actualAllowInvalidReason && !event) {
    throw new TypeError("Event should be provided if reason is expected");
  }
  const now = actualNowRef || /* @__PURE__ */ new Date();
  const sortedPrices = ticket.prices.sort((a, b) => a.effectiveDate < b.effectiveDate ? 1 : -1);
  const selectedPrice = sortedPrices.find((sp) => sp.effectiveDate < now);
  if (selectedPrice) {
    return selectedPrice;
  } else if (!actualAllowInvalidReason) {
    return void 0;
  }
  if (sortedPrices.length === 0) {
    return { reason: "NO_PRICE" };
  }
  if (sortedPrices[0].effectiveDate > now) {
    return {
      reason: "FUTURE_TICKET",
      price: sortedPrices[0]
    };
  }
  return { reason: "UNAVAILABLE" };
}
function isNullScope(scope) {
  return Object.entries(scope).every(([, v]) => v === null || v === void 0);
}
function isTicketValidForVideo(ticket, video, videoId) {
  if (ticket.scope === null || !isTicketRestricted(ticket)) {
    return true;
  }
  let covered = typeof ticket.scope.initialCoverage === "boolean" ? ticket.scope.initialCoverage : true;
  if (ticket.scope.tags) {
    covered = (ticket.scope.tags.kind === "ALL" ? allTagsApply : someTagsApply)(
      video.tags,
      ticket.scope.tags.list
    );
  }
  if (ticket.scope.excludeTags) {
    const shouldBeExcluded = (ticket.scope.excludeTags.kind === "ALL" ? allTagsApply : someTagsApply)(video.tags, ticket.scope.excludeTags.list);
    covered = shouldBeExcluded ? false : covered;
  }
  if (ticket.scope.attributes) {
    const shouldInclude = checkAttributeCoverage(ticket.scope.attributes, video);
    covered = shouldInclude ? true : covered;
  }
  if (ticket.scope.excludeAttributes) {
    const shouldExclude = checkAttributeCoverage(ticket.scope.excludeAttributes, video);
    covered = shouldExclude ? false : covered;
  }
  if (ticket.scope.videoIds) {
    const shouldBeIncluded = ticket.scope.videoIds.includes(videoId);
    covered = shouldBeIncluded ? true : covered;
  }
  if (ticket.scope.excludeVideoIds) {
    const shouldBeExcluded = ticket.scope.excludeVideoIds.includes(videoId);
    covered = shouldBeExcluded ? false : covered;
  }
  return covered;
}
function isSoldTicketStillValid(soldTicket, event, scope, videoId) {
  if (soldTicket.status !== "ACTIVE") {
    return false;
  }
  if (videoId !== void 0 && scope !== null && scope.redemptionLimit !== null && !soldTicket.videoIdsAccessed.includes(videoId)) {
    return false;
  }
  return !willTicketExpireBy(event, scope, soldTicket.redemptionTime || void 0);
}
function isTicketRestricted(ticket) {
  const { scope } = ticket;
  return scope !== null && !isNullScope(scope);
}
function isSingleVideoTicket(ticket) {
  return isTicketRestricted(ticket) && ticket.scope?.redemptionLimit === 1;
}
function getTicketExpiry(ticketScope, event) {
  return ticketScope && ticketScope.validityAfterRedemptionHours ? {
    type: "RELATIVE",
    hours: ticketScope.validityAfterRedemptionHours
  } : {
    type: "ABSOLUTE",
    date: event.endTime
  };
}
function willTicketExpireBy(event, ticketScope, redeemedDate = /* @__PURE__ */ new Date(), _nowRef = /* @__PURE__ */ new Date()) {
  const expiry = getTicketExpiry(ticketScope, event);
  const expiryTime = expiry.type === "RELATIVE" ? redeemedDate.getTime() + expiry.hours * 60 * 60 * 1e3 : expiry.date.getTime();
  return expiryTime < _nowRef.getTime();
}
function isTicketAvailableForPurchase(ticket, event, nowRef) {
  const now = nowRef || /* @__PURE__ */ new Date();
  return ticket.available && !!getEffectivePrice(ticket, now) && !willTicketExpireBy(event, ticket.scope, now, now);
}

export { getEffectivePrice, getTicketExpiry, isSingleVideoTicket, isSoldTicketStillValid, isTicketAvailableForPurchase, isTicketRestricted, isTicketValidForVideo, willTicketExpireBy };

