function getActivePage(pages) {
  return pages.find((p) => p.active);
}
function getDefaultHeaderTexts(page, context) {
  const emptyTexts = {
    text: "",
    subText: ""
  };
  if ("docId" in page) {
    return emptyTexts;
  }
  if (!context.event && !context.namedTag) {
    return {
      text: context.channel.name,
      subText: context.channel.description
    };
  } else if (context.event) {
    return {
      text: context.event.title,
      subText: context.event.description
    };
  } else if (context.namedTag) {
    return {
      text: context.namedTag.displayName === null ? context.namedTag.name : context.namedTag.displayName,
      subText: context.namedTag.description
    };
  } else {
    return emptyTexts;
  }
}
function getDefaultBannerImages(page, context) {
  let bannerImages = page.brandingOverride?.bannerImages;
  let logoImage = "";
  if (!bannerImages) {
    bannerImages = context.channel.branding.bannerImages;
  }
  if (context.namedTag) {
    logoImage = context.namedTag.logo;
    bannerImages = {
      "16/9": context.namedTag.cover ?? "",
      "3/1": context.namedTag.cover ?? "",
      "3/4": context.namedTag.cover ?? ""
    };
  }
  return {
    bannerImages,
    logoImage
  };
}
function getPageHeader(page, context) {
  const headerModule = page.header ? page.header : {
    ...getDefaultHeaderTexts(page, context),
    bannerImages: "INHERIT",
    promoBlocks: void 0,
    logoImage: ""
  };
  return {
    text: headerModule.text,
    subText: headerModule.subText,
    ...headerModule.promoBlocks === void 0 ? {} : { promoBlocks: headerModule.promoBlocks },
    ...headerModule.bannerImages === "INHERIT" ? getDefaultBannerImages(page, context) : { bannerImages: headerModule.bannerImages, logoImage: headerModule.logoImage }
  };
}
function isTabbedPageModules(modules) {
  return !Array.isArray(modules);
}
function getPageModules(page, tabId) {
  if (!isTabbedPageModules(page.modules)) {
    return page.modules;
  }
  const tabs = getSortedPageTabs(page.modules);
  let selectedTab = void 0;
  if (tabId) {
    selectedTab = tabs.find((t) => t.id === tabId);
  }
  selectedTab = selectedTab ?? tabs.find((t) => t.default);
  selectedTab = selectedTab ?? tabs[0];
  return selectedTab ? selectedTab.modules : [];
}
function getSortedPageTabs(pageTabs) {
  return Object.entries(pageTabs).map(([id, tab]) => {
    return { id, ...tab };
  }).sort((ta, tb) => ta.order - tb.order);
}

export { getActivePage, getPageHeader, getPageModules, getSortedPageTabs, isTabbedPageModules };

