import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  ButtonPromotionalBlock,
  CardPromotionalBlock,
  HeaderModule,
  PromoButtonAction,
  PromotionalBlock,
} from '@yoimo/interfaces';
import { Observable, map, of, switchMap } from 'rxjs';
import { ChannelWithDocId } from '@yoimo/client-sdk/channels';
import {
  PlanWithDocId,
  getPlanAlternatives,
  shouldShowTrialForPlan,
} from '@yoimo/client-sdk/subscriptions';
import { formatPrice } from '@core/utilities';
import { AuthService } from './auth.service';
import { Firestore } from '@angular/fire/firestore';
import { planToPlanOption } from '../../features/ticket-options/data-transformers';
import { PurchaseOption } from '@core/interfaces/types';

@Injectable({
  providedIn: 'root',
})
export class PromotionService {
  readonly getAccessLabel = $localize`:@@GetAccessButtonLabel:Get access`;
  readonly getTicketLabel = $localize`:@@eventGetTicketBtnLabel:Get ticket`;
  readonly getTicketsLabel = $localize`:@@eventGetTicketsBtnLabel:Get tickets`;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private authService: AuthService,
    private fs: Firestore
  ) {}

  getPromotionalBlock$(
    headerModule: HeaderModule,
    channel: ChannelWithDocId,
    defaultPurchaseOptions$?: Observable<PurchaseOption[]>
  ): Observable<PromotionalBlock | undefined> {
    if (!headerModule.promoBlocks) {
      return defaultPurchaseOptions$
        ? this.getDefaultPromotion$(defaultPurchaseOptions$, channel)
        : of(undefined);
    }
    if (!headerModule.promoBlocks.length || !headerModule.promoBlocks[0]) {
      return of(undefined);
    }
    // TODO: Handle multiple promotionBlocks
    return of(headerModule.promoBlocks[0]);
  }

  getDefaultPromotion$(
    defaultPurchaseOptions$: Observable<PurchaseOption[]>,
    channel: ChannelWithDocId
  ): Observable<PromotionalBlock | undefined> {
    let singleTicketAction: PromoButtonAction;
    return defaultPurchaseOptions$.pipe(
      switchMap((products) => {
        let label = this.getAccessLabel;
        // Nothing to promote
        if (!products.length) {
          return of(undefined);
        }

        if (products.length === 1) {
          // Default promo card for single plan
          if ('plan' in products[0]) {
            return this.getDefaultPromoCard$(channel, products[0].plan);
          }
          // Default 'Get ticket' button for single ticket
          label = this.getTicketLabel;
          singleTicketAction = {
            text: '',
            linkTo: {
              type: 'EVENT_TICKET',
              id: `${products[0].event.docId}:${products[0].ticket.id}`,
            },
          };
        }

        // Only plans for purchase
        if (
          products.length > 1 &&
          products.every((product) => 'plan' in product)
        ) {
          const plans = products
            .map((product) => ('plan' in product ? product.plan : undefined))
            .filter((plan): plan is PlanWithDocId => !!plan);
          label = this.getDefaultMultipleSubscriptionButtonLabel(plans);
        }

        // Only tickets for purchase
        if (
          products.length > 1 &&
          products.every((product) => 'ticket' in product)
        ) {
          label = this.getTicketsLabel;
        }

        // Default promo button for single or multiple options on a page
        const defaultPromoButton: ButtonPromotionalBlock = {
          condition: null,
          type: 'BUTTON',
          label: label,
          details: '',
          hideIfNoValidAction: true,
          action: singleTicketAction || {
            text: '',
            linkTo: {
              type: 'LIST_OPTIONS',
              dialogTitle: '',
              sections: [
                {
                  title: '',
                  ids: products.map((p) => {
                    if ('plan' in p) {
                      return `plan:${p.plan.docId}`;
                    }
                    return `ticket:${p.event.docId}:${p.ticket.id}`;
                  }),
                },
              ],
              hideUnavailableOptions: true,
            },
          },
        };
        return of(defaultPromoButton);
      })
    );
  }

  getDefaultPromoCard$(
    channel: ChannelWithDocId,
    plan: PlanWithDocId
  ): Observable<CardPromotionalBlock> {
    let allowTrial$ = of(true);
    const user = this.authService.auth.currentUser;
    if (user) {
      allowTrial$ = shouldShowTrialForPlan(
        this.fs,
        channel.docId,
        user.uid,
        plan.docId
      );
    }
    return allowTrial$.pipe(
      map((allowTrial) => {
        const planOption = planToPlanOption(
          channel,
          plan,
          this.locale,
          allowTrial
        );
        return {
          condition: null,
          type: 'CARD',
          title: planOption.cardDetails.title,
          promoBlock: planOption.cardDetails.discountStripTitle
            ? {
                title: planOption.cardDetails.discountStripTitle,
                description:
                  planOption.cardDetails.discountStripDescription || '',
              }
            : null,
          actionBlocks: planOption.cardDetails.planPrices.map((alt) => {
            return {
              discountTag: alt.discount,
              label: alt.name,
              details:
                alt.description || alt.trialPeriod
                  ? `${alt.description || ''} ${alt.trialPeriod || ''}`
                  : '',
              action: {
                text: alt.price,
                linkTo: {
                  type: 'PLAN',
                  id: `${plan.docId}:${alt.periodicity}`,
                },
              },
            };
          }),
          // TODO: set default info text when available
          infoText: '',
          hideIfNoValidAction: true,
        };
      })
    );
  }

  getDefaultMultipleSubscriptionButtonLabel(plans: PlanWithDocId[]): string {
    const fromPrice = plans.reduce(
      (cheapest, plan) => {
        const cheaper = getPlanAlternatives(plan).find(
          // Find an alternative that's cheaper than the current cheapest
          ({ currentPrice }) => currentPrice < cheapest.price
        );

        if (!cheaper) {
          return cheapest;
        }

        return {
          price: cheaper.currentPrice,
          currency: cheaper.currency,
        };
      },
      { price: Infinity, currency: '' }
    );
    const parsedPrice = formatPrice(
      this.locale,
      fromPrice.price,
      fromPrice.currency
    );
    return $localize`:@@PromotionSubscribeButtonLabel:Subscriptions start at ${parsedPrice}`;
  }
}
