import { declareCallable } from '../base/callable.js';
import '@angular/fire/firestore';
import 'rxjs';
import 'rxjs/operators';

let cachedCallable;
function buySubscription(ff, channelId, planId, periodicity, paymentMethodId, selectedCurrency, coupon, installments) {
  if (!cachedCallable) {
    cachedCallable = declareCallable("payments-buysubscription", ff);
  }
  return cachedCallable({
    channelId,
    planId,
    selectedCurrency,
    periodicity,
    paymentMethodId,
    coupon,
    installments
  });
}

export { buySubscription };

