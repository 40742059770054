import { getDocData, collectionRef } from '../base/collection.js';
import '@angular/fire/functions';
import 'rxjs';
import 'rxjs/operators';
import { execQuery } from '../base/query.js';
import { converter } from './PlanDataConverter.js';

function PLANS_COLLECTION(channelId) {
  return `channels/${channelId}/plans`;
}
function colRef(fs, channelId) {
  return collectionRef(PLANS_COLLECTION(channelId), fs, converter());
}
function getPlan(fs, channelId, id) {
  return getDocData(id, colRef(fs, channelId));
}
function getPlans(fs, channelId, options) {
  const wheres = [];
  if (!options.allowArchived) {
    wheres.push(["archived", "==", false]);
  }
  if (!options.allowUnavailable) {
    wheres.push(["available", "==", true]);
  }
  return execQuery(colRef(fs, channelId), wheres, void 0);
}

export { getPlan, getPlans };

