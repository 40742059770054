import { declareCallable } from '../base/callable.js';
import '@angular/fire/firestore';
import 'rxjs';
import 'rxjs/operators';

let cachedCallable;
function applyCoupon(ff, code, channelId, planId, selectedPeriodicity, selectedCurrency) {
  if (!cachedCallable) {
    cachedCallable = declareCallable("payments-applycoupon", ff);
  }
  return cachedCallable({
    channelId,
    planId,
    selectedCurrency,
    selectedPeriodicity,
    code
  });
}

export { applyCoupon };

