import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'parsePeriodicity', standalone: true })
export class ParsePeriodicityPipe implements PipeTransform {
  transform(value: string): string {
    if (value === 'YEARLY') {
      return $localize`:@@planOptionYearlyLabel:Yearly`;
    }
    if (value === 'MONTHLY') {
      return $localize`:@@planOptionMonthlyLabel:Monthly`;
    }
    return value;
  }
}
