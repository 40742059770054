import '@angular/fire/functions';
import 'rxjs';
import 'rxjs/operators';
import '@angular/fire/firestore';
import { cacheConverter, DefaultDataConverter } from '../base/DefaultDataConverter.js';
import { ChannelPublicSettings } from '@yoimo/interfaces/validators';

class ChannelPublicSettingsDataConverter extends DefaultDataConverter {
  constructor() {
    super(ChannelPublicSettings);
  }
}
const converter = cacheConverter(ChannelPublicSettingsDataConverter);

export { ChannelPublicSettingsDataConverter, converter };

