class DefaultDataConverter {
  constructor(validator) {
    this.validator = validator;
  }
  toFirestore(modelObject) {
    const { docId, ...entity } = modelObject;
    if (!this.validator(entity) && this.validator.errors) {
      const err = this.validator.errors[0];
      throw new TypeError(`Invalid data provided for ${err.instancePath}: ${err.message}
${JSON.stringify(err)}`);
    }
    return entity;
  }
  fromFirestore(snapshot) {
    const data = this.sanitizeDataFromFirebase(snapshot.data());
    if (!this.validator(data)) {
      if (this.validator.errors) {
        const errors = this.validator.errors.map((err) => `${err.instancePath} (${err.schemaPath}): ${err.message}`);
        throw new TypeError(`Invalid data provided in document ${snapshot.id}. ${errors.join("\n")}`);
      }
    }
    return { docId: snapshot.id, ...data };
  }
  sanitizeDataFromFirebase(data) {
    return this.sanitizeDatesFromFirebase(data);
  }
  sanitizeDatesFromFirebase(data) {
    if (this.isFirestoreTimestamp(data)) {
      return data.toDate();
    } else if (Array.isArray(data)) {
      return data.map((d) => this.sanitizeDatesFromFirebase(d));
    } else if (typeof data === "object" && data !== null) {
      const newData = {};
      for (const [k, value] of Object.entries(data)) {
        newData[k] = this.sanitizeDatesFromFirebase(value);
      }
      return newData;
    } else {
      return data;
    }
  }
  isFirestoreTimestamp(data) {
    return data !== null && typeof data === "object" && "seconds" in data && "nanoseconds" in data;
  }
}
function cacheConverter(converter) {
  let _conv;
  return () => {
    if (!_conv) {
      _conv = new converter();
    }
    return _conv;
  };
}

export { DefaultDataConverter, cacheConverter };

