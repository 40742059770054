import { converter } from './PageDataConverter.js';
import { collectionRef, getDocData } from '../base/collection.js';
import { map } from 'rxjs';
import { execQuery } from '../base/query.js';

function PAGES_COLLECTION(channelId) {
  return `channels/${channelId}/pages`;
}
function colRef(fs, channelId) {
  return collectionRef(PAGES_COLLECTION(channelId), fs, converter());
}
function getChannelPageById(fs, channelId, id) {
  return getDocData(id, colRef(fs, channelId));
}
function getPageByName(fs, channelId, name) {
  const wheres = [
    ["name", "==", name],
    ["status", "==", "PUBLISHED"]
  ];
  return execQuery(colRef(fs, channelId), wheres, { qLimit: 1 }).pipe(
    map((result) => result[0])
  );
}

export { colRef, getChannelPageById, getPageByName };

