import { forkJoin, of, map } from 'rxjs';
import { getPlan } from '../subscriptions/plans.crud.js';
import { getHightlightedPriceConfig } from '../subscriptions/plans.ops.js';
import '@angular/fire/functions';
import 'rxjs/operators';
import '@angular/fire/firestore';
import { isDefined } from '../utils.js';
import { isPlanAvailableForPurchase } from '../business-logic/subscription.js';
import '@yoimo/interfaces/validators';
import { getEvent } from '../events/crud.js';
import { isTicketAvailableForPurchase } from '../business-logic/tickets.js';
import { getTickets } from '../events/tickets.js';

function resolveSingleOption(singleOption, plansCache, eventsCache) {
  const baseOption = {
    openInNewContext: singleOption.openInNewContext,
    type: singleOption.type
  };
  switch (singleOption.type) {
    case "URL":
      return {
        ...baseOption,
        url: singleOption.id
      };
    case "PLAN": {
      const [planId, periodicity] = singleOption.id.split(":");
      const plan = plansCache.get(planId);
      if (!plan) {
        return void 0;
      }
      const selectedPeriodicity = periodicity === "MONTHLY" || periodicity === "YEARLY" ? periodicity : getHightlightedPriceConfig(plan).period;
      return {
        ...baseOption,
        plan,
        periodicity: selectedPeriodicity
      };
    }
    case "EVENT_TICKET": {
      const [eventId, ticketId] = singleOption.id.split(":");
      const event = eventsCache.get(eventId);
      const ticket = !event ? void 0 : event.tickets.find((t) => t.id === ticketId);
      if (!ticket || !event) {
        return void 0;
      }
      return { ...baseOption, ticket, event };
    }
    default:
      return void 0;
  }
}
function resolveOptionsListLinkSection(section, plansCache, eventsCache, hideUnavailableOptions) {
  const listTickets = (event) => !hideUnavailableOptions ? event.tickets : getTickets(event, {
    excludeExpired: true,
    includeTicketsWithLimitedRedemptions: false
    //#TODO: turn this ON when redemption limited tickets can be purchased independently
  });
  const products = section.ids.map((id) => {
    const idSections = id.split(":");
    if (idSections[0] === "plan" && idSections[1]) {
      const plan = plansCache.get(idSections[1]);
      return !plan || hideUnavailableOptions && !isPlanAvailableForPurchase(plan) ? void 0 : { plan };
    } else if (idSections[0] === "ticket" && idSections[1] && idSections[2]) {
      const event = eventsCache.get(idSections[1]);
      if (!event) {
        return void 0;
      }
      const tickets = listTickets(event);
      const selectedTickets = idSections[2].split(",").flatMap((tId) => tId === "*" ? tickets : tickets.find((t) => t.id === tId)).filter(isDefined);
      return selectedTickets.map((ticket) => ({ event, ticket }));
    }
    return void 0;
  }).filter(isDefined).flat(1);
  return {
    ...section,
    products
  };
}
function resolvedOptionsListLink(optionsList, plansCache, eventsCache) {
  return {
    ...optionsList,
    sections: optionsList.sections.map((section) => resolveOptionsListLinkSection(section, plansCache, eventsCache, !!optionsList.hideUnavailableOptions))
  };
}
function resolveLinkTo(link, plansCache, eventsCache) {
  return link.type !== "LIST_OPTIONS" ? resolveSingleOption(link, plansCache, eventsCache) : resolvedOptionsListLink(link, plansCache, eventsCache);
}
function isResolvedLinkValid(link) {
  if (link.type === "URL") {
    return true;
  } else if ("ticket" in link) {
    return isTicketAvailableForPurchase(link.ticket, link.event);
  } else if ("plan" in link) {
    return isPlanAvailableForPurchase(link.plan);
  } else if (link.type === "LIST_OPTIONS") {
    return link.sections.some((s) => s.products.length > 0);
  } else {
    return false;
  }
}
function resolveCardPromotionalBlock(promoBlock, plansCache, eventsCache) {
  const mappedActions = promoBlock.actionBlocks.map((block) => {
    const resolvedLink = resolveLinkTo(block.action.linkTo, plansCache, eventsCache);
    if (!resolvedLink || promoBlock.hideIfNoValidAction && !isResolvedLinkValid(resolvedLink)) {
      return void 0;
    }
    return {
      ...block,
      action: {
        text: block.action.text,
        linkTo: resolvedLink
      }
    };
  }).filter(isDefined);
  return promoBlock.hideIfNoValidAction && mappedActions.length === 0 ? void 0 : {
    ...promoBlock,
    actionBlocks: mappedActions
  };
}
function resolveButtonPromotionalBlock(promoBlock, plansCache, eventsCache) {
  const resolvedLink = resolveLinkTo(promoBlock.action.linkTo, plansCache, eventsCache);
  if (!resolvedLink || promoBlock.hideIfNoValidAction && !isResolvedLinkValid(resolvedLink)) {
    return void 0;
  }
  return {
    ...promoBlock,
    action: {
      ...promoBlock.action,
      linkTo: resolvedLink
    }
  };
}
function resolvePromotionalBlock(fs, channelId, promoBlock) {
  const targetEventIds = /* @__PURE__ */ new Set();
  const targetPlanIds = /* @__PURE__ */ new Set();
  const actions = promoBlock.type === "BUTTON" ? [promoBlock.action.linkTo] : promoBlock.actionBlocks.map((ab) => ab.action.linkTo);
  for (const action of actions) {
    switch (action.type) {
      case "EVENT_TICKET":
        targetEventIds.add(action.id.split(":")[0]);
        break;
      case "URL":
        break;
      case "PLAN":
        targetPlanIds.add(action.id.split(":")[0]);
        break;
      case "LIST_OPTIONS":
        action.sections.flatMap((s) => s.ids).forEach((i) => {
          const [t, id] = i.split(":");
          switch (t) {
            case "plan":
              targetPlanIds.add(id);
              break;
            case "ticket":
              targetEventIds.add(id);
              break;
          }
        });
    }
  }
  return forkJoin([
    targetPlanIds.size > 0 ? forkJoin(Array.from(targetPlanIds).map((id) => getPlan(fs, channelId, id))) : of([]),
    targetEventIds.size > 0 ? forkJoin(Array.from(targetEventIds).map((id) => getEvent(fs, channelId, id))) : of([])
  ]).pipe(
    map(([plans, events]) => {
      const plansCache = new Map(plans.filter(isDefined).map((p) => [p.docId, p]));
      const eventCache = new Map(events.filter(isDefined).map((e) => [e.docId, e]));
      return promoBlock.type === "CARD" ? resolveCardPromotionalBlock(promoBlock, plansCache, eventCache) : resolveButtonPromotionalBlock(promoBlock, plansCache, eventCache);
    })
  );
}

export { resolveLinkTo, resolvePromotionalBlock };

