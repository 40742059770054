/* istanbul ignore file */

import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  filter,
  firstValueFrom,
  map,
  of,
  switchMap,
  take,
  distinct,
  reduce,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  GetAccessGrantedResponse,
  Plan,
  SoldTicket,
  Ticket,
} from '@yoimo/interfaces';
import {
  PlanWithDocId,
  SubscriptionWithDocId,
  SubscriptionWithPlan,
  getPlans,
  getUsersSubscriptionsWithPlans,
} from '@yoimo/client-sdk/subscriptions';
import { VideoWithDocId, getAccessToVideo } from '@yoimo/client-sdk/videos';
import {
  getValidPlans,
  isPlanCoverageIncluded,
  isSoldTicketStillValid,
  isSubscriptionStillValid,
  isTicketRestricted,
  subscriptionCanUnlockAccess,
} from '@yoimo/client-sdk/business-logic';

import { AnalyticsOptions } from 'src/app/video-player/analytics';
import { AuthService } from './auth.service';
import { ChannelWithDocId } from '@yoimo/client-sdk/channels';
import { EventWithDocId, getEvent, getTickets } from '@yoimo/client-sdk/events';
import { Firestore } from '@angular/fire/firestore';
import { Functions } from '@angular/fire/functions';
import { Injectable } from '@angular/core';
import { getSoldTickets } from '@yoimo/client-sdk/users';
import { User } from '@angular/fire/auth';
import { PagedQueryCommand, WithDocId } from '@yoimo/client-sdk/base';
import { pushToDataLayer } from '@core/utilities';

export type UserAccessType = 'TICKET' | 'SUBSCRIPTION' | 'FREE';
export type VideoAccessCheckSuccessResult = {
  access: true;
  accessType: UserAccessType;
  videoUrl?: string;
  playerContext: Partial<AnalyticsOptions>;
};

export type VideoAccessCheckFailureReasons =
  | 'GEO_RESTRICTION'
  | 'CONTENT_GONE'
  | 'ACCESS_FAILED'
  | 'NEEDS_ACTION'
  | 'SUBSCRIPTION_PAUSED'
  | 'NO_ACCESS';
export type VideoAccessCheckFailureResult = {
  access: false;
  reason: VideoAccessCheckFailureReasons;
  isOptionsAvailable?: boolean;
};

export type VideoAccessCheckResult =
  | VideoAccessCheckSuccessResult
  | VideoAccessCheckFailureResult;

export type UserAccessLevel =
  | 'NO_ACCESS'
  | 'PARTIAL_ACCESS'
  | 'FULL_ACCESS'
  | 'SUBSCRIBED';

interface ChannelCheckCacheEntry {
  channelId: string;
  plans: {
    lastCheck: Date;
    entries: PlanWithDocId[];
    subscriptions: SubscriptionWithPlan[];
  };
  events: Map<string, EventCheckCacheEntry>;
}

interface EventCheckCacheEntry {
  lastCheck: Date;
  event: EventWithDocId;
  soldTickets: SoldTicket[];
}

/**
 * Minimum time between access control checks.
 */
const MIN_STALL_TIME = 5 * 60 * 1000;

/**
 * Utility class serving as a cache for different access checks.
 */
class ChannelCacheMap {
  private cacheMap: Map<string, ChannelCheckCacheEntry> = new Map();
  private pendingChecks: Map<string, ReplaySubject<ChannelCheckCacheEntry>> =
    new Map();
  private channelEntryUpdated$ = new ReplaySubject<{
    channelId: string;
    eventId?: string;
  }>();

  constructor(private fs: Firestore) {}

  private shouldRefreshChannelAccessEntry(
    channelId: string,
    eventId: string | undefined
  ): boolean {
    const entry = this.cacheMap.get(channelId);
    const stallCheck = (d: Date) => d.getTime() < Date.now() - MIN_STALL_TIME;
    if (!entry || stallCheck(entry.plans.lastCheck)) {
      // Entry needs a refresh because it's too old
      return true;
    }

    if (!eventId) {
      return false;
    }
    const eventEntry = entry.events.get(eventId);
    return !eventEntry || stallCheck(eventEntry.lastCheck);
  }

  private ensureChannelEntry(channelId: string): ChannelCheckCacheEntry {
    let entry = this.cacheMap.get(channelId);
    if (!entry) {
      entry = {
        channelId,
        events: new Map(),
        plans: {
          lastCheck: new Date(),
          entries: [],
          subscriptions: [],
        },
      };
      this.cacheMap.set(channelId, entry);
    }
    return entry;
  }

  async updatePlanEntries(
    channelId: string,
    eventId?: string,
    userId?: string
  ): Promise<void> {
    const entry = this.ensureChannelEntry(channelId);

    entry.plans.lastCheck = new Date();
    entry.plans.entries =
      (await firstValueFrom(
        getPlans(this.fs, channelId, {
          allowArchived: false,
          allowUnavailable: false,
        })
      )) || [];

    // Fetch users valid subscriptions
    entry.plans.subscriptions = !userId
      ? []
      : await firstValueFrom(
          getUsersSubscriptionsWithPlans(this.fs, channelId, userId).pipe(
            map((subs) => {
              return subs.filter((sub) =>
                isSubscriptionStillValid(sub.subscription)
              );
            })
          )
        );

    if (eventId) {
      this.updateEventsEntries(entry, eventId, userId);
      return;
    }
    this.channelEntryUpdated$.next({ channelId });
  }

  private async ensureEventEntry(
    channelEntry: ChannelCheckCacheEntry,
    eventId: string
  ): Promise<EventCheckCacheEntry> {
    let eventEntry = channelEntry.events.get(eventId);
    if (!eventEntry) {
      const event = await firstValueFrom(
        getEvent(this.fs, channelEntry.channelId, eventId)
      );
      if (!event) {
        throw new Error('Event not found');
      }
      eventEntry = {
        lastCheck: new Date(),
        soldTickets: [],
        event,
      };
      channelEntry.events.set(eventId, eventEntry);
    }
    return eventEntry;
  }

  private async updateEventsEntries(
    channelEntry: ChannelCheckCacheEntry,
    eventId: string,
    userId?: string
  ): Promise<void> {
    const entry = await this.ensureEventEntry(channelEntry, eventId);
    entry.lastCheck = new Date();

    const pager = new BehaviorSubject<PagedQueryCommand>('init');
    // Fetch users sold tickets for event
    const soldTickets$ = !userId
      ? of([])
      : getSoldTickets(this.fs, userId, {
          scope: 'CHANNEL',
          scopeId: channelEntry.channelId,
          eventId: eventId,
          status: 'ACTIVE',
          pager: pager,
        }).pipe(
          reduce((data, res) => {
            data = [...data, ...res.data];
            if (res.paging.canLoadNext) {
              pager.next('next');
            } else {
              pager.next('complete');
              pager.complete();
            }
            return data;
          }, [] as WithDocId<SoldTicket>[])
        );

    entry.soldTickets = await firstValueFrom(soldTickets$);
    this.channelEntryUpdated$.next({
      channelId: channelEntry.channelId,
      eventId,
    });
  }

  private getOngoingRequestKey(
    channelId: string,
    eventId: string | undefined
  ): string {
    return [channelId, eventId].join(':');
  }

  public updateChannelEntry(
    channelId: string,
    eventId: string | undefined,
    userId: string | undefined,
    forceRefresh: boolean = false
  ): void {
    // first check if there is an ongoing request
    const reqKey = this.getOngoingRequestKey(channelId, eventId);
    const ongoing = this.pendingChecks.get(reqKey);
    if (ongoing) {
      return;
    }

    const entry = this.cacheMap.get(channelId);
    const eventEntry = entry?.events.get(eventId || '');

    if (
      !eventId &&
      entry &&
      !forceRefresh &&
      !this.shouldRefreshChannelAccessEntry(channelId, eventId)
    ) {
      return;
    }

    if (
      eventId &&
      eventEntry &&
      !forceRefresh &&
      !this.shouldRefreshChannelAccessEntry(channelId, eventId)
    ) {
      return;
    }

    // ReplaySubject is needed here so that the last result is shared amongs all observers
    const subject = new ReplaySubject<ChannelCheckCacheEntry>(1);
    this.pendingChecks.set(reqKey, subject);

    if (entry && eventId && !eventEntry) {
      this.updateEventsEntries(entry, eventId, userId);
    } else {
      this.updatePlanEntries(channelId, eventId, userId);
    }

    this.getChannelEntryUpdates$(channelId, eventId, userId)
      .pipe(take(1))
      .subscribe((entry) => {
        subject.next(entry);
        subject.complete();
        this.pendingChecks.delete(reqKey);
      });
  }

  public getChannelEntryUpdates$(
    channelId: string,
    eventId?: string,
    userId?: string
  ): Observable<ChannelCheckCacheEntry> {
    // If cache is empty load data
    this.updateChannelEntry(channelId, eventId, userId);

    return this.channelEntryUpdated$.pipe(
      filter((key) => key.channelId === channelId && key.eventId === eventId),
      map((key) => {
        const channelEntry = this.cacheMap.get(key.channelId);
        if (!channelEntry) throw new Error('Channel Entry not found');
        return channelEntry;
      })
    );
  }
}

/**
 * Holds the information regarding user access to specific resources.
 * When asked if a user has access to a specific area, the service will
 * either check its current knowledge base, or contact the backend.
 * A call to the backend is always needed to get the video URL.
 */
@Injectable({ providedIn: 'root' })
export class AccessControlService {
  /**
   * Keeps track what the user has access to.
   */
  private channelCheckMap: ChannelCacheMap;

  private _requiresUserAction$ = new ReplaySubject<SubscriptionWithDocId>(1);
  readonly requiresUserAction$ = this._requiresUserAction$.asObservable();

  // Emit every time user does a purchase or cancel operation
  private _userPaymentUpdate$ = new BehaviorSubject<void>(undefined);
  readonly userPaymentUpdate$ = this._userPaymentUpdate$.asObservable();

  constructor(
    private ff: Functions,
    private authService: AuthService,
    private fs: Firestore
  ) {
    this.channelCheckMap = new ChannelCacheMap(this.fs);
  }
  /**
   * Returns a list of applicable products depending on the channelId scoped to given video or event.
   * This doesn't call the backend and relies on the cache. Every time the
   * cache updates it will return the updated products list.
   * @param channelId channelId to check
   * @return
   */
  getApplicablePlans$(
    channelId: string,
    event?: EventWithDocId,
    video?: VideoWithDocId
  ): Observable<PlanWithDocId[]> {
    return this.authService.user$.pipe(
      switchMap((user) =>
        this.channelCheckMap.getChannelEntryUpdates$(
          channelId,
          event?.docId,
          user?.uid
        )
      ),
      map((entry) => {
        return getValidPlans(entry.plans.entries, video, event);
      })
    );
  }

  /**
   * Checks if the user has access to a given channel with a subscription
   * @param channelId The channel ID to check
   */
  isUserSubscribedToChannel$(
    channel: ChannelWithDocId,
    event?: EventWithDocId,
    video?: VideoWithDocId
  ): Observable<boolean> {
    return combineLatest([
      this.authService.user$,
      this.userPaymentUpdate$,
    ]).pipe(
      switchMap(([user, _update]) => {
        if (!user) {
          AccessControlService.trackResourceAccess(
            'CHANNEL',
            channel.docId,
            channel.name,
            'NO_ACCESS'
          );
          return of(false);
        }

        return getUsersSubscriptionsWithPlans(
          this.fs,
          channel.docId,
          user.uid
        ).pipe(
          take(1),
          map((subscriptions) => {
            if (!subscriptions.length) {
              return false;
            }
            const subscriptionRequiresAction = subscriptions.find(
              (sub) => sub.subscription.requiredUserAction
            );
            if (subscriptionRequiresAction) {
              this._requiresUserAction$.next(
                subscriptionRequiresAction.subscription
              );
            }
            const validSubscriptions = subscriptions.filter((sub) =>
              isSubscriptionStillValid(sub.subscription)
            );

            // If event or video is passed check if user's plans cover the event/video
            if (event || video) {
              return !!getValidPlans(
                validSubscriptions.map((s) => s.plan),
                video,
                event
              ).length;
            }
            return validSubscriptions.length > 0;
          }),
          tap((res) => {
            AccessControlService.trackResourceAccess(
              'CHANNEL',
              channel.docId,
              channel.name,
              res ? 'SUBSCRIBED' : 'NO_ACCESS'
            );
          })
        );
      })
    );
  }

  doesUserSubscriptionsCoverPlan(
    channel: ChannelWithDocId,
    user: User,
    plan: PlanWithDocId
  ): Observable<boolean> {
    return getUsersSubscriptionsWithPlans(
      this.fs,
      channel.docId,
      user.uid
    ).pipe(
      map((userSubsWithPlan) => {
        return userSubsWithPlan
          .filter((sub) => isSubscriptionStillValid(sub.subscription))
          .some((sub) =>
            isPlanCoverageIncluded(plan, plan.docId, sub.plan, sub.plan.docId)
          );
      })
    );
  }

  refreshChannelEntry(
    channel: ChannelWithDocId,
    eventId: string | undefined
  ): void {
    this._userPaymentUpdate$.next();
    const userId = this.authService.auth.currentUser?.uid;
    this.channelCheckMap.updateChannelEntry(
      channel.docId,
      eventId,
      userId,
      true
    );
  }

  private getUserEventAccessLevel$(
    user: User,
    channel: ChannelWithDocId,
    event: EventWithDocId
  ): Observable<UserAccessLevel> {
    return combineLatest([
      this.isUserSubscribedToChannel$(channel, event),
      this.channelCheckMap.getChannelEntryUpdates$(
        channel.docId,
        event.docId,
        user.uid
      ),
    ]).pipe(
      map(([subscribed, channelEntry]) => {
        if (subscribed) return 'SUBSCRIBED';
        const eventEntry = channelEntry.events.get(event.docId);
        if (!eventEntry) throw new Error('Event Entry not found');

        const soldTickets = eventEntry.soldTickets;
        if (soldTickets.length > 0) {
          const tickets = soldTickets.map((st) =>
            event.tickets.find(
              (t) =>
                st.ticketId === t.id &&
                isSoldTicketStillValid(st, event, t.scope, undefined)
            )
          );
          if (!tickets.length) return 'NO_ACCESS';

          return tickets.some((t) => t && !isTicketRestricted(t))
            ? 'FULL_ACCESS'
            : 'PARTIAL_ACCESS';
        }
        return 'NO_ACCESS';
      }),
      distinct(),
      tap((res) => {
        AccessControlService.trackResourceAccess(
          'EVENT',
          event.docId,
          event.title,
          res
        );
      })
    );
  }

  // Check if user has acces to an Event
  userHasEventAccess$(
    user: User | null,
    channel: ChannelWithDocId,
    event: EventWithDocId
  ): Observable<boolean> {
    if (!user) {
      AccessControlService.trackResourceAccess(
        'EVENT',
        event.docId,
        event.title,
        'NO_ACCESS'
      );
      return of(false);
    }

    return this.getUserEventAccessLevel$(user, channel, event).pipe(
      map((accessLevel) => {
        AccessControlService.trackResourceAccess(
          'EVENT',
          event.docId,
          event.title,
          accessLevel
        );
        return accessLevel === 'FULL_ACCESS' || accessLevel === 'SUBSCRIBED';
      })
    );
  }

  /**
   * Calls the backend to check if a user has access to a video.
   * @param video
   */
  checkAccessToVideo(
    video: VideoWithDocId
  ): Observable<VideoAccessCheckResult> {
    return getAccessToVideo(this.ff, video).pipe(
      map((result) => {
        AccessControlService.trackResourceAccess(
          'VIDEO',
          video.docId,
          video.name,
          result.granted ? 'FULL_ACCESS' : 'NO_ACCESS'
        );

        if (result.granted) {
          return {
            access: true,
            accessType: result.accessType.type,
            videoUrl: result.url,
            playerContext: this.getViewContext(result),
          };
        }
        let failReason: VideoAccessCheckFailureReasons = 'NO_ACCESS';
        const availableOptions = result.availableOptions;
        switch (result.reason) {
          case 'SUBSCRIPTION_INVALID':
          case 'TICKET_AND_SUBSCRIPTION_INVALID':
            if (
              result.invalidSubscriptions?.some(
                (obj) => obj.status === 'NEEDS_ACTION'
              )
            ) {
              failReason = 'NEEDS_ACTION';
              break;
            }
            const pausedSubPlanIds = result.invalidSubscriptions
              ? result.invalidSubscriptions
                  .filter((s) => s.status === 'PAUSED')
                  .map((s) => s.planId)
              : [];
            if (pausedSubPlanIds.length && availableOptions) {
              availableOptions.plans = availableOptions?.plans?.filter(
                (p) => !pausedSubPlanIds.includes(p.id)
              );
              failReason = 'SUBSCRIPTION_PAUSED';
            }
            break;
          case 'NOT_REGISTERED':
          case 'NO_ACCESS':
          case 'TICKET_EXPIRED':
            failReason = 'NO_ACCESS';
            break;
          default:
            failReason = result.reason;
        }
        return {
          access: false,
          reason: failReason,
          isOptionsAvailable: this.isOptionsAvailable(availableOptions),
        };
      })
    );
  }

  isOptionsAvailable(
    options:
      | undefined
      | { plans?: { plan: Plan; id: string }[]; tickets?: Ticket[] }
  ): boolean {
    return (
      !!options &&
      ((!!options.plans && options.plans.length > 0) ||
        (!!options.tickets && options.tickets.length > 0))
    );
  }

  getViewContext(
    accessResponse: GetAccessGrantedResponse
  ): Partial<AnalyticsOptions> {
    let transactionCode: string = 'FREE';
    let userType: string = 'REGISTERED';
    let contentPrice: string = '0';
    let currentCurrency: string = 'NA';

    switch (accessResponse.accessType.type) {
      case 'SUBSCRIPTION': {
        transactionCode =
          'SUB:' + accessResponse.accessType.subscriptionEntryId;
        const planId = accessResponse.accessType.planId;
        const { currency, periodicity } =
          accessResponse.accessType.subscriptionEntry.selectedConfig;
        const selectedPlan = accessResponse.availableOptions?.plans?.find(
          (p) => p.id === planId
        )?.plan;
        const price = selectedPlan?.priceAlternatives
          .find((pa) => pa.currency === currency)
          ?.billingPeriods.find((p) => p.period === periodicity)?.basePrice;
        contentPrice = `${price}`;
        userType = `SUB:${periodicity}:${
          accessResponse.accessType.subscriptionEntry.coupon || ''
        }`;
        currentCurrency = currency;
        break;
      }
      case 'TICKET': {
        transactionCode =
          'TICKET:' + accessResponse.accessType.ticketEntries[0].id;
        const { price, currency } =
          accessResponse.accessType.ticketEntries[0].entry.selectedConfig;
        contentPrice = `${price}`;
        currentCurrency = currency;
        userType = `TICKET`;
        break;
      }
    }

    return {
      username: this.authService.auth.currentUser?.uid,
      'content.transactionCode': transactionCode,
      'content.price': contentPrice,
      'content.cost': currentCurrency,
      userType: userType,
    };
  }

  isVideoLocked$(
    channelId: string,
    video: VideoWithDocId
  ): Observable<boolean> {
    if (video.unlocked) {
      return of(false);
    }
    return this.authService.user$.pipe(
      switchMap((user) =>
        this.channelCheckMap
          .getChannelEntryUpdates$(channelId, undefined, user?.uid)
          .pipe(map((channelEntry) => ({ user, channelEntry })))
      ),
      switchMap(({ user, channelEntry }) => {
        if (!user) {
          return of(true);
        }

        // Check if user's subscription unlocks a video
        if (channelEntry.plans.subscriptions.length) {
          const subsThatGiveAccess = channelEntry.plans.subscriptions.filter(
            (sub) => subscriptionCanUnlockAccess(sub.subscription)
          );
          return of(
            getValidPlans(
              subsThatGiveAccess.map((sub) => sub.plan),
              video
            ).length === 0
          );
        }

        if (!video.eventId) {
          return of(true);
        }

        return this.channelCheckMap
          .getChannelEntryUpdates$(channelId, video.eventId, user?.uid)
          .pipe(
            map((channelEntry) => channelEntry.events.get(video.eventId || ''))
          );
      }),
      map((res: boolean | EventCheckCacheEntry | undefined) => {
        if (typeof res === 'boolean') {
          return res;
        }

        if (!res || res.soldTickets.length === 0) {
          return true;
        }

        // Check if user's ticket unlocks a video
        return (
          this.getSoldTicketsThatGiveAccessToVideo(
            res.soldTickets,
            res.event,
            video
          ).length === 0
        );
      })
    );
  }

  getSoldTicketsThatGiveAccessToVideo(
    soldTickets: SoldTicket[],
    event: EventWithDocId,
    video: VideoWithDocId
  ): SoldTicket[] {
    const eventTickets = getTickets(event, {
      excludeExpired: true,
      includeInvalid: false,
      includeTicketsWithLimitedRedemptions: true,
      video: video,
    });
    return soldTickets.filter(
      (st) =>
        !!eventTickets.find(
          (t) =>
            t.id === st.ticketId &&
            isSoldTicketStillValid(st, event, t.scope, video.docId)
        )
    );
  }

  private static trackResourceAccess(
    resourceType: 'CHANNEL' | 'EVENT' | 'VIDEO',
    resourceId: string,
    resourceName: string,
    accessLevel: UserAccessLevel
  ): void {
    pushToDataLayer<'resource_access'>('resource_access', {
      'resource.access_level': accessLevel,
      'resource.id': resourceId,
      'resource.name': resourceName,
      'resource.type': resourceType,
    });
  }
}
