import { Observable, map, of, combineLatest, switchMap } from 'rxjs';

import { Injectable } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ScopeService } from './scope.service';
import {
  getAuthenticatedUserInfo,
  getChannelsSubscriptions,
} from '@yoimo/client-sdk/users';
import { ChannelWithDocId } from '@yoimo/client-sdk/channels';
import { Firestore } from '@angular/fire/firestore';
import { Auth } from '@angular/fire/auth';
import { AuthService } from './auth.service';
import { getUsersSubscriptionsWithPlans } from '@yoimo/client-sdk/subscriptions';
import { AccessControlService } from './access-control.service';
import { getSport80Settings$ } from '../../features/profile/sport80/sport80.utils';
import { ConsentService } from './consent.service';

export interface ProfileRoute {
  path: string;
  route: RouterLink['routerLink'];
  icon: string;
  label: string;
}

@Injectable({ providedIn: 'root' })
export class ProfileService {
  private readonly channel$ = this.scopeService.listenToChannel();

  constructor(
    private scopeService: ScopeService,
    private auth: Auth,
    private fs: Firestore,
    private authService: AuthService,
    private accessControlService: AccessControlService,
    private consentService: ConsentService
  ) {}

  getProfileRoutes$(): Observable<ProfileRoute[]> {
    return combineLatest([
      this.scopeService.scope$,
      this.getUserChannels$(),
      this.showSubscriptionsTab$(),
      this.showPrivacySection$(),
      this.getSport80$(),
    ]).pipe(
      map(([scope, channels, showSubscriptions, showPrivacy, sport80]) => {
        const homepageUrl = this.scopeService.getHomepageUrlFromScope(scope);

        const routes = [
          {
            path: 'tickets',
            route: [homepageUrl, 'profile', 'tickets'],
            icon: 'ticket',
            label: $localize`:@@profilePageTicketsPathLabel:Tickets`,
          },
        ];
        if (false) {
          routes.push({
            path: 'following',
            route: [homepageUrl, 'profile', 'following'],
            icon: 'star_shiny_outline',
            label: 'Following',
          });
        }
        if (showPrivacy) {
          routes.push({
            path: 'privacy',
            route: [homepageUrl, 'profile', 'privacy'],
            icon: 'shield',
            label: $localize`:@@profilePagePrivacyPathLabel:Privacy`,
          });
        }
        if (showSubscriptions) {
          routes.unshift({
            path: 'subscriptions',
            route: [homepageUrl, 'profile', 'subscriptions'],
            icon: 'coin',
            label: $localize`:@@profilePageSubscriptionsPathLabel:Subscriptions`,
          });
        }
        if (channels.length > 1) {
          routes.push({
            path: 'channels',
            route: [homepageUrl, 'profile', 'channels'],
            icon: 'channel',
            label: $localize`:@@profilePageChannelsPathLabel:Channels`,
          });
        }
        if (sport80?.enabled) {
          routes.push({
            path: 'sport80',
            route: [homepageUrl, 'profile', 'integrations', 'sport80'],
            icon: 'sport80',
            label: sport80.label,
          });
        }
        return routes;
      })
    );
  }

  getUserChannels$(): Observable<ChannelWithDocId[]> {
    const userInfo$ = getAuthenticatedUserInfo(this.auth, this.fs);
    if (!userInfo$) return of([]);

    return userInfo$.pipe(
      switchMap((userInfo) => {
        return getChannelsSubscriptions(userInfo, this.fs);
      })
    );
  }

  private showSubscriptionsTab$(): Observable<boolean> {
    const { currentUser } = this.authService.auth;
    if (!currentUser?.uid) {
      return of(false);
    }
    return this.channel$.pipe(
      switchMap((channel) =>
        combineLatest([
          getUsersSubscriptionsWithPlans(
            this.fs,
            channel.docId,
            currentUser?.uid
          ),
          this.hasAvailablePlans$(channel),
        ])
      ),
      map(([userSubscriptions, hasPlans]) => {
        return !!userSubscriptions.length || hasPlans;
      })
    );
  }

  private hasAvailablePlans$(channel: ChannelWithDocId): Observable<boolean> {
    return this.accessControlService
      .getApplicablePlans$(channel.docId)
      .pipe(map((plans) => plans.length > 0));
  }

  private getSport80$() {
    return combineLatest([this.authService.user$, this.channel$]).pipe(
      switchMap(([user, channel]) =>
        getSport80Settings$(this.fs, channel.docId, user?.uid || '')
      )
    );
  }

  private showPrivacySection$(): Observable<boolean> {
    return this.channel$.pipe(
      switchMap((channel) =>
        this.consentService.getChannelRequests$(channel.docId)
      ),
      map((res) => res.flat().length > 0)
    );
  }
}
