import 'rxjs';
import '@angular/fire/firestore';
import 'rxjs/operators';
import '@angular/fire/functions';
import '@yoimo/interfaces/validators';
import { getUserConsentRequests } from '../channels/ops.js';

function checkUserConsent(channelSettings, consentEntry) {
  const requestedConsents = getUserConsentRequests(channelSettings);
  const userConsentCheck = {
    groups: requestedConsents.groups,
    needUpdate: [],
    required: [],
    upToDate: []
  };
  for (const request of requestedConsents.requests) {
    const response = consentEntry?.consents && consentEntry.consents[request.id];
    if (response && request.version === response.consentedVersion) {
      userConsentCheck.upToDate.push({ request, response });
    } else {
      !response ? userConsentCheck.required.push(request) : userConsentCheck.needUpdate.push({ response, request });
    }
  }
  return userConsentCheck;
}
function checkTermsConsent(channelSettings, consentEntry) {
  const requestedConsents = getUserConsentRequests(channelSettings);
  const termsConsentCheck = {
    needUpdate: [],
    required: [],
    upToDate: []
  };
  for (const terms of requestedConsents.terms) {
    const response = consentEntry?.terms && consentEntry.terms[terms.id];
    if (response && terms.version === response.consentedVersion) {
      termsConsentCheck.upToDate.push({ terms, response });
    } else {
      !response ? termsConsentCheck.required.push(terms) : termsConsentCheck.needUpdate.push({ terms, response });
    }
  }
  return termsConsentCheck;
}

export { checkTermsConsent, checkUserConsent };

