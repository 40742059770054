import '@angular/fire/functions';
import 'rxjs';
import 'rxjs/operators';
import '@angular/fire/firestore';
import { cacheConverter, DefaultDataConverter } from '../base/DefaultDataConverter.js';
import { Channel } from '@yoimo/interfaces/validators';

class ChannelDataConverter extends DefaultDataConverter {
  constructor() {
    super(Channel);
  }
}
const converter = cacheConverter(ChannelDataConverter);

export { ChannelDataConverter, converter };

