import {
  Directive,
  EmbeddedViewRef,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { TextParserService } from '../services/text-parser.service';
/**
 * Parse the text content of the element this directive is applied and
 * replaces it with a formatted version. The parser implementation is
 * handled by the TextParserService which can be used to implement
 * MarkDown for example.
 */
@Directive({ selector: '[yoFormatText]', standalone: true })
export class FormatTextDirective {
  @Input() set yoFormatText(input: string) {
    if (input === this.input) {
      return;
    }
    this.embeddedView =
      this.embeddedView ||
      this.viewContainer.createEmbeddedView(this.templateRef);
    this.renderView(this.embeddedView, input);
  }

  embeddedView?: EmbeddedViewRef<any>;
  input?: string;
  constructor(
    private parser: TextParserService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  private renderView(viewRef: EmbeddedViewRef<any>, input: string) {
    const parsedNodes = this.parser.parse(input);
    this.parser.replaceContent(viewRef.rootNodes[0], parsedNodes);
  }
}
