import { allTagsApply, someTagsApply } from './tags.js';
import { checkAttributeCoverage } from './util.js';

const KnownSubscriptionProviders = ["SPORT80", "INTERNAL", "STRIPE"];
const VALID_SUBSCRIPTION_STATUSES = ["ACTIVE", "CANCELLING", "TRIALING", "NEEDS_ACTION", "PAUSED"];
const UNLOCKING_SUBSCRIPTION_STATUSES = ["ACTIVE", "CANCELLING", "TRIALING"];
function isSubscriptionStillValid(sub) {
  return VALID_SUBSCRIPTION_STATUSES.includes(sub.status);
}
function subscriptionCanUnlockAccess(sub) {
  return UNLOCKING_SUBSCRIPTION_STATUSES.includes(sub.status);
}
function isSubscriptionCancellable(sub) {
  const [provider] = sub.subscriptionId.split(":");
  if (provider !== "STRIPE") {
    return false;
  }
  const cancellableStatus = [
    "ACTIVE",
    "TRIALING",
    "NEEDS_ACTION"
    // TODO: reintroduce this when we support cancelling from a paused state
    //"PAUSED",
  ];
  return cancellableStatus.includes(sub.status);
}
function subscriptionStateAfterCancellation(sub) {
  switch (sub.status) {
    case "ACTIVE":
      return "CANCELLING";
    case "PAUSED":
      return "CANCELLING";
    case "NEEDS_ACTION":
      return "TERMINATED";
    case "TRIALING":
      return "TERMINATED";
    default:
      return "TERMINATED";
  }
}
function isSubscriptionUncancelable(sub) {
  const [provider] = sub.subscriptionId.split(":");
  if (provider !== "STRIPE") {
    return false;
  }
  return !!(sub.cancellation?.canUncancel && sub.status === "CANCELLING");
}
function isNullScope(scope) {
  return Object.entries(scope).every(([, v]) => v === null || v === void 0);
}
function isPlanStillValid(plan) {
  return !plan.archived;
}
function isPlanAvailableForPurchase(plan) {
  return isPlanStillValid(plan) && plan.available;
}
function getPeriodSubdivisions(basePeriodicity, subdivision) {
  const subdivisionMap = {
    "YEARLY": {
      "MONTHLY": 12
    },
    "MONTHLY": {
      "MONTHLY": 1
    }
  };
  const base = subdivisionMap[basePeriodicity];
  if (!base) {
    throw new TypeError("Unknown subdivision");
  }
  const factor = base[subdivision];
  if (!factor) {
    throw new TypeError("Unknown subdivision");
  }
  return factor;
}
function getRecurringPriceParameters(config, useInstallments) {
  let paymentPeriodicity;
  let bindingPeriods;
  let totalPerPeriod;
  if (useInstallments && !config.installments) {
    throw new TypeError("Can't get parameters for installments if not present in subscription");
  } else if (useInstallments && config.installments) {
    paymentPeriodicity = config.installments.period;
    bindingPeriods = config.installments.bindingCycles * getPeriodSubdivisions(config.period, config.installments.period);
    totalPerPeriod = config.installments.price;
  } else {
    paymentPeriodicity = config.period;
    bindingPeriods = 1;
    totalPerPeriod = config.basePrice;
  }
  const totalOverBindingPeriod = bindingPeriods * totalPerPeriod;
  return {
    paymentPeriodicity,
    bindingPeriods,
    totalPerPeriod,
    totalOverBindingPeriod
  };
}
function getInitialDiscount(config, useInstallments = false, _nowRef = void 0) {
  if (useInstallments) {
    return 0;
  }
  const now = _nowRef ?? /* @__PURE__ */ new Date();
  if (!config.initialPhaseDiscount || config.initialPhaseDiscount.offerEndDate !== null && config.initialPhaseDiscount.offerEndDate < now || config.initialPhaseDiscount.cycles instanceof Date && config.initialPhaseDiscount.cycles < now) {
    return 0;
  }
  return Math.round(
    "percentOff" in config.initialPhaseDiscount.discount ? Math.round(config.basePrice * config.initialPhaseDiscount.discount.percentOff / 100) : config.initialPhaseDiscount.discount.amountOff
  );
}
function getSubscriptionPauseBehaviour(sub, plan) {
  const periods = plan.priceAlternatives.find((a) => a.currency === sub.selectedConfig.currency);
  const selectedAlternative = periods?.billingPeriods.find((a) => a.period === sub.selectedConfig.periodicity);
  return selectedAlternative?.pauseBehaviour;
}
function canPauseSubscription(sub, plan, duration) {
  const pauseBehaviour = getSubscriptionPauseBehaviour(sub, plan);
  if (sub.status !== "ACTIVE" || !pauseBehaviour || sub.selectedConfig.installments) {
    return false;
  }
  const allowedToPause = !sub.pauseDate || sub.pauseDate.getTime() < Date.now() - pauseBehaviour.onceEvery * 24 * 3600 * 1e3;
  const validDuration = duration === void 0 ? true : pauseBehaviour.periods.some((d) => {
    if (typeof duration === "number") {
      return d.period === duration;
    } else {
      return d.period instanceof Date && d.period.getTime() === duration.getTime();
    }
  });
  return allowedToPause && validDuration;
}
function whenCanPause(sub, plan) {
  const pauseBehaviour = getSubscriptionPauseBehaviour(sub, plan);
  if (!pauseBehaviour) {
    return "never";
  }
  const now = Date.now();
  const dateToPause = !sub.pauseDate ? now : sub.pauseDate.getTime() + pauseBehaviour.onceEvery * 24 * 3600 * 1e3;
  return dateToPause >= now ? "now" : new Date(dateToPause);
}
function canUnpauseSubscription(sub, plan) {
  const pauseBehaviour = getSubscriptionPauseBehaviour(sub, plan);
  return sub.status === "PAUSED" && !!pauseBehaviour && pauseBehaviour.canUnpause;
}
function getProviderOfSubscription(subscription) {
  return subscription.subscriptionId.split(":")[0];
}
function markParentsAsValid(id, parentPlans, plansValidity) {
  const parents = parentPlans.get(id);
  if (!parents) {
    return;
  }
  parents.forEach((parentId) => {
    const parentPlan = plansValidity.get(parentId);
    if (!parentPlan || parentPlan.valid)
      return;
    parentPlan.valid = true;
    markParentsAsValid(parentId, parentPlans, plansValidity);
  });
}
function checkVideoCoverage(initialCoverage, scope, video) {
  let videoCoverage = initialCoverage;
  if (scope.tags) {
    const shouldInclude = scope.tags.kind === "ALL" ? allTagsApply(video.tags, scope.tags.list) : someTagsApply(video.tags, scope.tags.list);
    videoCoverage = shouldInclude ? true : videoCoverage;
  }
  if (scope.excludeTags) {
    const shouldExclude = scope.excludeTags.kind === "ALL" ? allTagsApply(video.tags, scope.excludeTags.list) : someTagsApply(video.tags, scope.excludeTags.list);
    videoCoverage = shouldExclude ? false : videoCoverage;
  }
  if (scope.attributes) {
    const shouldInclude = checkAttributeCoverage(scope.attributes, video);
    videoCoverage = shouldInclude ? true : videoCoverage;
  }
  if (scope.excludeAttributes) {
    const shouldExclude = checkAttributeCoverage(scope.excludeAttributes, video);
    videoCoverage = shouldExclude ? false : videoCoverage;
  }
  return videoCoverage;
}
function getValidPlans(plans, video, event) {
  const parentPlans = /* @__PURE__ */ new Map();
  const plansValidity = /* @__PURE__ */ new Map();
  for (const plan of plans) {
    const scope = plan.scope;
    if (scope?.includedPlans) {
      scope.includedPlans.forEach((pId) => {
        const pp = parentPlans.get(pId) || [];
        pp.push(plan.docId);
        parentPlans.set(pId, pp);
      });
    }
    if (scope === null || isNullScope(scope)) {
      plansValidity.set(plan.docId, { valid: true, plan });
      continue;
    }
    const initialCoverage = scope.initialCoverage === false ? false : true;
    const videoCoverage = video ? checkVideoCoverage(initialCoverage, scope, video) : initialCoverage;
    let eventCoverage = initialCoverage;
    if (event && event.includedInPlans && event.includedInPlans.includes(plan.docId)) {
      eventCoverage = true;
    }
    if (event && event.excludedFromPlans && event.excludedFromPlans.includes(plan.docId)) {
      eventCoverage = false;
    }
    const covered = scope.predicateCombination === "AND" ? eventCoverage && videoCoverage : eventCoverage || videoCoverage;
    plansValidity.set(plan.docId, { plan, valid: covered });
  }
  for (const k of parentPlans.keys()) {
    if (plansValidity.get(k)?.valid) {
      markParentsAsValid(k, parentPlans, plansValidity);
    }
  }
  return plans.filter((p) => plansValidity.get(p.docId)?.valid);
}
function isPlanCoverageIncluded(_plan, planId, testPlan, testPlanId) {
  if (testPlan.scope === null || isNullScope(testPlan.scope)) {
    return true;
  }
  if (testPlan.scope.includedPlans?.includes(planId)) {
    return true;
  }
  if (planId === testPlanId) {
    return true;
  }
  return false;
}

export { KnownSubscriptionProviders, UNLOCKING_SUBSCRIPTION_STATUSES, VALID_SUBSCRIPTION_STATUSES, canPauseSubscription, canUnpauseSubscription, getInitialDiscount, getPeriodSubdivisions, getProviderOfSubscription, getRecurringPriceParameters, getSubscriptionPauseBehaviour, getValidPlans, isPlanAvailableForPurchase, isPlanCoverageIncluded, isPlanStillValid, isSubscriptionCancellable, isSubscriptionStillValid, isSubscriptionUncancelable, subscriptionCanUnlockAccess, subscriptionStateAfterCancellation, whenCanPause };

