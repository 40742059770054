/* istanbul ignore file */

import { FormControl } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Utilitary pipe to typecast an AbstractControl as FormControl.
 * This allows binding a form group control to a FormControl input.
 */
@Pipe({ name: 'valueAsFormControl', standalone: true })
export class ValueAsFormControlPipe implements PipeTransform {
  transform(value: unknown | null): FormControl {
    return new FormControl(value);
  }
}
