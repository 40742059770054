import { declareCallable } from '../base/callable.js';
import '@angular/fire/firestore';
import 'rxjs';
import 'rxjs/operators';

let cachedCallable;
function createSetupIntent(ff, channelId) {
  if (!cachedCallable) {
    cachedCallable = declareCallable("payments-createsetupintent", ff);
  }
  return cachedCallable({ channelId });
}

export { createSetupIntent };

