import '@angular/fire/functions';
import 'rxjs';
import 'rxjs/operators';
import '@angular/fire/firestore';
import { cacheConverter, DefaultDataConverter } from '../base/DefaultDataConverter.js';
import { Feedback } from '@yoimo/interfaces/validators';

class FeedbackConverter extends DefaultDataConverter {
  constructor() {
    super(Feedback);
  }
}
const converter = cacheConverter(FeedbackConverter);

export { FeedbackConverter, converter };

