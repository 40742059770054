import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

import { Injectable } from '@angular/core';
import { WindowService } from '@yoimo/joymo-ui';
import { environment } from '@environment';

const { favicons } = environment.behavior.testFeatures;

@Injectable({ providedIn: 'root' })
export class MetaService {
  metaTypes: string[] = ['og', 'twitter'];

  constructor(
    private title: Title,
    private meta: Meta,
    private windowService: WindowService
  ) {}

  setTags(metaTags: {
    title: string;
    description: string;
    image: string;
  }): void {
    for (const [property, content] of Object.entries(metaTags)) {
      for (const source of this.metaTypes) {
        if (property && content) {
          this.addTag({
            property: `${source}:${property}`,
            content,
          });
        }
      }
    }

    if (metaTags.title) {
      this.title.setTitle(metaTags.title);
    }
    if (metaTags.description) {
      this.addTag({
        property: 'description',
        content: metaTags.description,
      });
    }
  }

  setRawTags(tags: MetaDefinition[]): void {
    for (const tag of tags) {
      this.addTag(tag);
      if (tag.property && tag.property == 'og:title' && tag.content) {
        this.title.setTitle(tag.content);
      }

      if (tag.property && tag.property == 'og:description' && tag.content) {
        this.addTag({
          property: 'description',
          content: tag.content,
        });
      }
    }
  }

  addTag(tag: MetaDefinition): HTMLMetaElement | null {
    let existingMeta = this.meta.getTag(`property='${tag.property}'`);
    if (existingMeta) {
      return existingMeta.content == tag.content
        ? existingMeta
        : this.meta.updateTag(tag);
    }

    return this.meta.addTag(tag);
  }

  /**
   * Temporary workaround to set the favicon
   *
   * @deprecated The icon should be pulled from the channel data
   */
  setFavicon(scopeId: string): void {
    const { document } = this.windowService;
    const element = document.createElement('link');
    element.setAttribute('rel', 'shortcut icon');
    element.setAttribute('href', favicons[scopeId] || favicons['default']);
    document.head.appendChild(element);
  }
}
