import { AbstractListModule } from '@yoimo/interfaces';
import { Density, Size } from '@yoimo/joymo-ui';

export const densitySizeMap: { [key in Density]: Size } = {
  normal: 'lg',
  dense: 'md',
  densest: 'sm',
};

export function getDensity(size: AbstractListModule['density']): Density {
  return size.toLowerCase() as Density;
}
