import { WindowService } from '@yoimo/joymo-ui';

const MOBILE_BROWSERS =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/gi;
const RECOMMENDED_BROWSERS = /chrome/gi;
const SKIP_RECOMMENDATION_KEY = 'skipBrowserRecommendation';

/**
 * Taken from [this stackoverflow answer](https://stackoverflow.com/a/9039885/8609340)
 *
 * @deprecated As `Navigator.platform` is [getting deprecated](https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform).
 * To fix this function, refer to [Navigator.userAgentData](https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData)
 */
export function iOS(): boolean {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export function isMobile(): boolean {
  if (!WindowService.isWindowAvailable) return false;
  if (!navigator.userAgent) return false;
  return MOBILE_BROWSERS.test(window.navigator.userAgent);
}

export const isRecommendedBrowser = (): boolean | null => {
  if (!WindowService.isWindowAvailable) return null;
  // Safari is the only browser available on iOS devices, ignore:
  if (iOS()) return null;
  return !!navigator.userAgent.match(RECOMMENDED_BROWSERS);
};

/**
 * Prevent showing the browser recommendations to user in the future
 */
export const skipBrowserRecommendations = (): void => {
  localStorage.setItem(SKIP_RECOMMENDATION_KEY, 'true');
};
