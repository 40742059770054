import { of, map, switchMap, forkJoin, defer } from 'rxjs';
import '@angular/fire/firestore';
import { isDefined } from '../utils.js';
import '@angular/fire/functions';
import 'rxjs/operators';
import '@yoimo/interfaces/validators';
import { getChannelPublicSettings, getVideoAttributesMapping } from '../channels/ops.js';
import { AttributeKeys } from '@yoimo/interfaces';
import { getEventsFromSource } from '../events/crud.js';
import { getNamedTagFromSourceList } from '../named-tags/crud.js';

function getEventFilterDefinition(fs, scope) {
  const getEvents = () => getEventsFromSource(fs, {}, { ...scope }).pipe(
    map(
      (events) => events.map((event) => ({ value: event.docId, label: event.title, icon: event.posterImage, valueObject: event }))
    )
  );
  return {
    source: "event",
    presentation: "search-list",
    label: "Event",
    useIcons: false,
    values$: defer(getEvents)
  };
}
function getAttributeFilterDefinition(mappings, attributeSource, hideIfNotPresent) {
  const { source } = attributeSource;
  const attributeMap = mappings.mappings[source];
  if (!attributeMap) {
    if (!hideIfNotPresent) {
      throw new Error("Attribute not set up correctly in the mappings");
    } else {
      return void 0;
    }
  }
  const valuesFilter = "groups" in attributeSource ? ({ group }) => !!group && attributeSource.groups.includes(group) : "values" in attributeSource ? ({ name }) => !!name && attributeSource.values.includes(name) : () => true;
  return {
    source,
    presentation: attributeMap.values.length > 10 ? "search-list" : "list",
    useIcons: attributeMap.values.some((v) => !!v.icon),
    label: attributeMap.label || attributeMap.name,
    groups: attributeMap.groups,
    values$: of(
      attributeMap.values.filter(valuesFilter).map((v) => ({ label: v.label || v.name, icon: v.icon, value: v.name, group: v.group, valueObject: null }))
    )
  };
}
function getNamedTagFilterDefinition(fs, scope, mappings, hideIfNotPresent) {
  const namedTagsAttribute = mappings.useNamedTagAsAttribute;
  if (!namedTagsAttribute) {
    if (!hideIfNotPresent) {
      throw new Error("Named tag is not configured properly for this filter");
    } else {
      return void 0;
    }
  }
  const namedTags = () => getNamedTagFromSourceList(
    fs,
    { type: namedTagsAttribute.type },
    { ...scope }
  ).pipe(
    map((tags) => tags.map((t) => ({ label: t.displayName || t.name, icon: t.logo, value: t.name, valueObject: t })))
  );
  return {
    source: "tag",
    presentation: "multi-select-search-list",
    label: namedTagsAttribute.label,
    useIcons: namedTagsAttribute.showIcon,
    values$: defer(namedTags)
  };
}
function getDefaultModuleFilters(channelSettings) {
  return channelSettings.attributesMapping?.defaultConfig ?? [
    { source: "event" },
    { source: "date" },
    { source: "tag" },
    ...AttributeKeys.map((source) => ({ source }))
  ];
}
function getModuleFilterLabels(fs, filter, scope, listSource, settings, hideIfNotPresent = false) {
  const channelSettings = settings ? of(settings) : getChannelPublicSettings(fs, scope.scopeId);
  return channelSettings.pipe(
    map((s) => getVideoAttributesMapping(s)),
    map(
      (mappings) => {
        if (!mappings && !hideIfNotPresent) {
          throw new Error("Attribute mappings not defined.");
        }
        switch (filter.source) {
          case "date":
            return { presentation: "date" };
          case "event":
            return listSource.eventId ? void 0 : getEventFilterDefinition(fs, scope);
          case "tag":
            return mappings && !listSource.tags ? getNamedTagFilterDefinition(fs, scope, mappings, hideIfNotPresent) : void 0;
          default:
            return mappings && (!listSource.attributes || !listSource.attributes[filter.source]) ? getAttributeFilterDefinition(mappings, filter, hideIfNotPresent) : void 0;
        }
      }
    )
  );
}
function getAllModuleFilterLabels(fs, filters, scope, settings, listSource, hideIfNotPresent = false) {
  const channelSettings = settings ? of(settings) : getChannelPublicSettings(fs, scope.scopeId);
  return channelSettings.pipe(
    switchMap(
      (channelSettings2) => forkJoin(
        (filters === "DEFAULT" ? getDefaultModuleFilters(channelSettings2) : filters).map(
          (f) => getModuleFilterLabels(fs, f, scope, listSource, channelSettings2, hideIfNotPresent)
        )
      )
    ),
    map((results) => results.filter(isDefined))
  );
}
function buildSourceAndQueryOptions(filterValues, options, listSource) {
  const videoListSource = {};
  const optionOverrides = { ...options };
  for (const value of filterValues) {
    if (!value.value)
      continue;
    switch (value.source) {
      case "event":
        videoListSource.eventId = value.value;
        break;
      case "date": {
        optionOverrides.dateRange = value.value;
        break;
      }
      case "tag":
        videoListSource.tags = { kind: "SOME", list: value.value };
        break;
      default: {
        videoListSource.attributes = videoListSource.attributes ?? {};
        videoListSource.attributes[value.source] = value.value;
      }
    }
  }
  return { source: { ...listSource, ...videoListSource }, options: optionOverrides };
}

export { buildSourceAndQueryOptions, getAllModuleFilterLabels, getModuleFilterLabels };

