import { getDocData$, collectionRef } from '../base/collection.js';
import { switchMap, of, forkJoin, map } from 'rxjs';
import { getPlan } from './plans.crud.js';
import '@angular/fire/functions';
import 'rxjs/operators';
import { execQuery } from '../base/query.js';
import { VALID_SUBSCRIPTION_STATUSES, UNLOCKING_SUBSCRIPTION_STATUSES } from '../business-logic/subscription.js';
import { converter } from './SubscriptionDataConverter.js';
import { isDefined } from '../utils.js';

function SUBSCRIPTION_COLLECTION(channelId) {
  return `channels/${channelId}/subscriptions`;
}
function colRef(fs, channelId) {
  return collectionRef(
    SUBSCRIPTION_COLLECTION(channelId),
    fs,
    converter()
  );
}
function getUsersSubscriptions(fs, channelId, userId, options) {
  const wheres = [
    ["userId", "==", userId]
  ];
  const qOpts = {};
  let st = [];
  if (options?.withStatus) {
    switch (options?.withStatus) {
      case "all":
        break;
      case "unlocking":
        st = [...UNLOCKING_SUBSCRIPTION_STATUSES];
        break;
      case "valid":
        st = [...VALID_SUBSCRIPTION_STATUSES];
        break;
      default:
        st = options.withStatus;
    }
  } else {
    st = [...VALID_SUBSCRIPTION_STATUSES];
  }
  if (st.length) {
    wheres.push(["status", "in", VALID_SUBSCRIPTION_STATUSES]);
  }
  if (options?.planId) {
    wheres.push(["planId", "==", options.planId]);
  }
  if (options?.qLimit) {
    qOpts.qLimit = options.qLimit;
  }
  return execQuery(colRef(fs, channelId), wheres, qOpts);
}
function getUsersSubscriptionsWithPlans(fs, channelId, userId) {
  return getUsersSubscriptions(fs, channelId, userId).pipe(
    switchMap((subscriptions) => {
      return subscriptions.length === 0 ? of([]) : forkJoin(
        subscriptions.map((s) => getPlan(fs, channelId, s.planId))
      ).pipe(
        map(
          (plans) => plans.filter(isDefined).map((p, i) => ({ subscription: subscriptions[i], plan: p }))
        )
      );
    })
  );
}
function shouldShowTrialForPlan(fs, channelId, userId, planId) {
  return getUsersSubscriptions(fs, channelId, userId, { planId, withStatus: "all", qLimit: 1 }).pipe(
    map((r) => r.length === 0)
  );
}
function getUserSubscription$(fs, channelId, subscriptionId) {
  return getDocData$(subscriptionId, colRef(fs, channelId));
}

export { getUserSubscription$, getUsersSubscriptions, getUsersSubscriptionsWithPlans, shouldShowTrialForPlan };

