function applyDiscount(basePrice, discount) {
  const p = Math.round(basePrice * 100);
  let d;
  if ("percentOff" in discount) {
    d = Math.round(p * (discount.percentOff / 100));
  } else {
    d = discount.amountOff * 100;
  }
  return (p - d) / 100;
}
function offerApplies(initialPhaseDiscount, _nowRef) {
  const offerEnd = initialPhaseDiscount.offerEndDate;
  return offerEnd === null || offerEnd > _nowRef;
}
function getCurrentPrice(billingConfig, _nowRef) {
  return billingConfig.initialPhaseDiscount && offerApplies(billingConfig.initialPhaseDiscount, _nowRef) ? applyDiscount(billingConfig.basePrice, billingConfig.initialPhaseDiscount.discount) : billingConfig.basePrice;
}
function getDiscountText(billingConfig, currency, currentPrice, _nowRef) {
  const priceBlock = billingConfig.priceBlock ? { ...billingConfig.priceBlock } : { label: billingConfig.period, action: [currency, currentPrice] };
  if (billingConfig.initialPhaseDiscount && offerApplies(billingConfig.initialPhaseDiscount, _nowRef)) {
    return {
      discountLabel: billingConfig.initialPhaseDiscount.overrides?.discountLabel || billingConfig.discountLabel,
      discountDescription: billingConfig.initialPhaseDiscount.overrides?.discountDescription || billingConfig.discountDescription,
      offer: {
        title: billingConfig.initialPhaseDiscount.title,
        description: billingConfig.initialPhaseDiscount.shortDescription,
        endDate: billingConfig.initialPhaseDiscount.offerEndDate
      },
      priceBlock: {
        ...priceBlock,
        ...billingConfig.initialPhaseDiscount.overrides?.priceBlock
      }
    };
  } else {
    return {
      discountLabel: billingConfig.discountLabel,
      discountDescription: billingConfig.discountDescription,
      offer: null,
      priceBlock
    };
  }
}
function getPlanAlternatives(plan, _nowRef) {
  const currency = plan.priceAlternatives[0].currency;
  const now = _nowRef ?? /* @__PURE__ */ new Date();
  return plan.priceAlternatives[0].billingPeriods.map((config) => {
    const currentPrice = getCurrentPrice(config, now);
    return {
      highlighted: config.highlighted,
      trialDays: config.trialDays,
      currency,
      periodicity: config.period,
      currentPrice,
      regularPrice: config.basePrice,
      ...getDiscountText(config, currency, currentPrice, now)
    };
  });
}
function getHightlightedPriceConfig(plan) {
  const hightlightedPriceConfig = plan.priceAlternatives[0].billingPeriods.findIndex((x) => x.highlighted);
  return plan.priceAlternatives[0].billingPeriods[hightlightedPriceConfig === -1 ? 0 : hightlightedPriceConfig];
}

export { getHightlightedPriceConfig, getPlanAlternatives };

